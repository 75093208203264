import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { fromContainer, belowContainer, profileContainer, belowTablet } from 'utilities/styled'
import { cls } from 'utilities/dom'
import { flexColumnsCss, flexRowsCss, flexChildCss, flexChildShrinkCss } from 'components/flex'
import { textCss } from 'components/form/input/text'
import { codeTextCss } from 'components/code/s'
import { loaderCss } from 'components/loader'
import Tooltip, { Trigger } from 'components/tooltip'
import SymbolBase from 'components/symbol'
import { paragraphSmallCss } from 'components/typography/paragraph'
import Caption, { captionSmallCss } from 'components/typography/caption'

const horizontalFieldCss = css`
    ${flexColumnsCss}

    &.vertical {
        ${flexRowsCss}
    }

    &:not(.compact) {
        margin: 0 0 16px;
    }

    &.wide {
        width: 100%;
    }
`

const verticalFieldCss = css`
    ${flexRowsCss}

    &.horizontal {
        ${flexColumnsCss}
    }

    &:not(.compact) {
        margin: 0 0 24px;
    }
`

const fieldCss = css`
    @layer form-field {
        *:where(form.horizontal) &,
        &.horizontal {
            ${horizontalFieldCss}
        }

        ${fromContainer(profileContainer)`
            ${horizontalFieldCss}
        `}

        *:where(form:not(.horizontal)) &,
        &.vertical {
            ${verticalFieldCss}
        }

        ${belowContainer(profileContainer)`
            ${verticalFieldCss}
        `}

        ${belowTablet`
            ${flexRowsCss}

            &.horizontal {
                ${flexColumnsCss}
            }
        `}
    }
`

export const Field = styled.div`
    ${fieldCss}
`

export const Fieldset = styled.fieldset`
    @layer form-field {
        margin: unset;
        border: unset;
        min-width: 0;
        padding: 0;
    }

    ${fieldCss}
`

// Neutral fieldset
export const FieldGroup = styled.div`
    ${fieldCss}

    &.framed {
        padding: 16px;
        outline: 1px solid var(--huma-color-border-default);
        border-radius: 4px;
    }

    &.rows {
        display: grid;
        row-gap: 8px;
        justify-content: stretch;
    }
`

const labelHorizontalCss = css`
    width: 200px;
    min-height: 40px;
    padding: 8px 40px 8px 0;
`

const labelVerticalCss = css`
    flex: 1 0 auto;
    width: auto;
    min-height: 24px;
    padding: 0;
    margin-bottom: 4px;
`

const labelCss = css`
    ${flexChildShrinkCss}
    position: relative;
    color: var(--huma-color-foreground-subtle);
    line-height: 24px;

    &[for] {
        cursor: pointer;
    }

    &.accent {
        color: var(--huma-color-foreground-default);
    }

    &.small {
        font-size: 14px;
        line-height: 20px;
    }

    *:where(form.horizontal) &,
    ${Field}.horizontal > &,
    &.horizontal {
        ${labelHorizontalCss}

        &.small {
            min-height: 32px;
        }
    }

    ${Field}.horizontal.compact > &,
    &.horizontal.compact {
        margin-block-end: 0;
    }

    ${fromContainer(profileContainer)`
        ${labelHorizontalCss}

        ${Field}.compact > & {
            margin-block-end: 0;
        }
    `}

    *:where(form:not(.horizontal)) &,
    ${Field}.vertical > &,
    &.vertical {
        ${labelVerticalCss}
    }

    ${Field}.vertical.compact > &,
    &.vertical.compact {
        margin-block-end: 0;
    }

    ${belowContainer(profileContainer)`
        ${labelVerticalCss}

        ${Field}.compact > & {
            margin-block-end: 0;
        }
    `}

    ${belowTablet`
        ${labelVerticalCss}
    `}

    ${Field}.important > & span {
        border-radius: 4px;
        padding-block: 8px;
        background: var(--huma-color-surface-highlight-subtle);
        box-shadow:
            4px 0 0 var(--huma-color-surface-highlight-subtle),
            -4px 0 0 var(--huma-color-surface-highlight-subtle);
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        color: var(--huma-color-foreground-default);
    }
`

const LabelBase = styled.label`
    ${labelCss}
`

const LegendBase = styled.legend`
    ${labelCss}

    display: block;
`

export const Required = styled.span`
    display: inline;

    margin-inline-start: 4px;

    color: var(--huma-color-foreground-destructive);
`

const LabelHelper = styled.span`
    display: block;

    font-size: 14px;
    line-height: 16px;
    color: var(--huma-color-foreground-subtle);
`

export const Label = ({ children, helper = null, required = false, optional = null, optionalProps = {}, ...props }) => (
    <LabelBase {...props}>
        <span>
            {children}
            {!!required && <Required>*</Required>}
            {!!optional && (
                <Tooltip
                    content={optional}
                    {...optionalProps}>
                    <Trigger />
                </Tooltip>
            )}
        </span>
        {helper && <LabelHelper>{helper}</LabelHelper>}
    </LabelBase>
)

export const Legend = ({ children, helper = null, required = false, optional = null, ...props }) => (
    <LegendBase {...props}>
        <span>
            {children}
            {!!required && <Required>*</Required>}
            {!!optional && (
                <Tooltip content={optional}>
                    <Trigger />
                </Tooltip>
            )}
        </span>
        {helper && <LabelHelper>{helper}</LabelHelper>}
    </LegendBase>
)

const loaderAfterCss = css`
    ${loaderCss}

    --__huma-component-loader-color: var(--huma-color-surface-inverted);

    content: "";
    inset-inline: auto 0;

    width: 32px;
`

export const Control = styled.div.attrs(attrs => ({
    ...attrs,
    className: cls(['control', attrs.className])
}))`
    ${flexChildCss}

    position: relative;

    &.framed {
        border: 1px solid var(--huma-color-border-default);
        border-radius: 8px;
    }

    ${Field}:not(.suffixed).loading &::after {
        ${loaderAfterCss}
    }
`

export const ControlMainColumn = styled.div`
    ${flexChildCss}

    position: relative;

    ${Field}.suffixed.loading &::after {
        ${loaderAfterCss}

        inset-block-end: auto;
        inset-inline-end: 8px;
    }
`

export const ValueDisplay = styled.div`
    ${textCss}
    height: auto;
    min-height: 40px;
    padding: 8px 0;
    line-height: 24px;
    border: none;

    &.single {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.multi {
        height: auto;
        max-height: 184px; /* 7 lines */
        overflow-y: auto;
        line-height: 24px;
        white-space: pre-wrap;
    }

    &.clamp {
        height: auto;
    }

    &.soft {
        color: var(--huma-color-foreground-subtle);
    }

    &.empty {
        color: var(--huma-color-foreground-minimal);

        @media (var(--contrast-boost)) {
            color: var(--huma-color-foreground-subtle);
        }
    }

    &.obscured {
        &,
        & > * {
            text-indent: 0;
            cursor: pointer;
            transition: all .5s ease-in-out;
        }

        &.revealed,
        &.revealed > * {
            color: var(--huma-color-foreground-default);
            text-shadow: none;
            transition: all .15s ease-in-out;
            cursor: default;
        }
    }

    &.with-icon {
        padding-right: 24px;
        position: relative;

        & > svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
    }

    &.code {
        ${codeTextCss}
    }
`

export const Emoji = styled.span`
    margin-inline-end: 8px;
`

export const Symbol = styled(SymbolBase)`
    vertical-align: middle;

    margin-inline-end: 8px;
`

export const Error = styled(motion.p).attrs(() => ({
    className: 'destructive',
    variants: {
        hide: {
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .5
            }
        }
    },
    initial: 'hide'
}))`
    position: absolute;
    top: calc(100% + 8px);
    left: auto;
    ${paragraphSmallCss}
`

export const Placeholder = styled(Caption).attrs(attrs => ({
    ...attrs,
    className: cls(['compact', attrs.className])
}))`
    padding-inline-end: 8px;

    color: var(--huma-color-foreground-minimal);
`

export const helperCss = css`
    ${captionSmallCss}
    margin-block: -16px 24px;

    .compact + &,
    &.compact {
        margin-block: 8px 0;
    }

    *:where(form.horizontal) &,
    ${Field}.horizontal &,
    ${Field}.horizontal:not(.compact) + & {
        margin-block: -8px 16px;

        &.compact {
            margin-block-end: 0;
        }
    }
`

export const Helper = styled.p`
    ${helperCss}
`

export const ErrorPlain = styled.p.attrs(() => ({ className: 'destructive' }))`
    ${helperCss}
`