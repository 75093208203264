import React, { Component } from 'react'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { omit } from 'utilities/object'
import { Field, Label } from 'components/form/field/s'
import { Control } from './s'
import Options from './options'

export const empty = null

class EditRating extends Component {
    constructor(props) {
        super(props)

        const { value } = getFieldFromProps(props)
        this.state = { value }

        this.register()
    }

    componentDidMount() {
        const { include } = getFieldFromProps(this.props)
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        const { include } = getFieldFromProps(this.props)
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required,
            include
        } = getFieldFromProps(this.props)

        if(include === 'never') {
            return
        }

        const { min = 1 } = this.props.field

        this.props.form.registerField(this.props.name, {
            type: 'number',

            empty,
            required,
            include,

            unset: this.unset,

            validator: value => {
                if(required) {
                    return (value !== empty && value >= min)
                }

                return true
            }
        }, update)
    }

    unset = () => {
        this.setState({ value: empty })

        this.props.form.triggerChange(this.props.name)
        this.props.onChange?.({ [this.props.name]: this.empty })
    }

    onChange = ({ target: { value } }) => {
        value = parseInt(value, 10)

        this.setState({ value })

        this.props.form.triggerChange(this.props.name)
        this.props.onChange?.({ [this.props.name]: value })
    }

    render() {
        const { value } = this.state

        const {
            className,
            salt,
            label,
            name,
            loading = false,
            labels = {},
            showValue = false
        } = this.props

        const {
            required,
            min,
            max,
            variant
        } = getFieldFromProps(this.props)

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required}>
                        {label}
                    </Label>
                )}
                <Control>
                    {/* <input
                        name={name}
                        type="hidden"
                        defaultValue={value}
                        key={`${name}:input:${value}`} /> */}
                    <Options
                        {...omit(this.props, 'form', 'field', 'whistle')}
                        name={name}
                        value={value}
                        min={min}
                        max={max}
                        variant={variant}
                        labels={labels}
                        showValue={showValue}
                        onChange={this.onChange}
                        salt={salt} />
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {} }) => {
    let {
        value,
        required = false,
        include = 'touched',
        ...rest
    } = field

    if(!value && value !== 0) {
        value = empty
    }

    return {
        ...rest,
        value,
        required,
        include
    }
}

export default props => {
    const form = useForm()

    return (
        <EditRating
            {...props}
            form={form} />
    )
}