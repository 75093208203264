import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { ListItem } from 'components/list'
import UserBase from 'components/person'

export const PersonItem = styled(ListItem)`
    margin: 0 32px 24px;

    ${belowTablet`
        margin: 0 24px 16px;
    `}
`

export const User = styled(UserBase)`
    overflow: hidden;
`