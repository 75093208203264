import styled, { css } from 'styled-components'
import {
    flashKeyframes, svgStroke,
    fromTablet, belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Columns } from 'components/flex'
import { Naked, Plain } from 'components/button'
import { H3 } from 'components/typography/heading'
import Caption, { CaptionSmall } from 'components/typography/caption'
import PersonBase from 'components/person'
import { TriggerTexts } from 'components/entities'
import { ancestorHover } from 'components/avatar/s'
import { compact } from 'utilities/array'
import {
    processModes,
    processTemplateModes,
    processDraftModes
} from 'lists/tasks'

export const TaskWrapper = styled(Grid)`
    column-gap: 24px;
    width: 100%;
    padding-block: 24px;
    position: relative;

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    ${({ theme }) => {
        const isProcessTemplateOrDraft = [
            ...processTemplateModes,
            ...processDraftModes
        ].includes(theme.mode)

        const columns = compact([
            // Toggle completed or avatar
            isProcessTemplateOrDraft ?
                '40px' :
                '20px',

            // Name + description + assignment info in onboarding contexts
            'minmax(auto, 2fr)',

            // Status
            (processTemplateModes.includes(theme.mode) || theme.tasks?.context === 'meeting-event') ?
                '' :
                '1fr',

            // Assigned to
            (processModes.includes(theme.mode) || ['dashboard', 'meeting-event'].includes(theme.tasks?.context)) ?
                '' :
                'minmax(0, 1fr)',

            // Actions
            !!theme.tasks?.actions && '40px'
        ])

        return css`
            grid-template-columns: ${columns.join(' ')};
            ${isProcessTemplateOrDraft ? ancestorHover() : ''}
        `
    }}

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    ${belowTablet`
        grid-template-columns: ${({ theme }) => ([
            ...processTemplateModes,
            ...processDraftModes
        ].includes(theme.mode)) ? '40px' : '20px'} minmax(auto, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
            "checkbox title"
            ". status";
        row-gap: 4px;
        column-gap: 24px;
        padding-block: 16px;
    `}

    svg {
        ${svgStroke()}
    }
`

export const CheckboxCell = styled.div`
    ${({ theme: { tasks: { context } } }) => {
        if(context !== 'dashboard') {
            return css`
                padding-top: 3px;
            `
        }
    }}

    svg {
        ${svgStroke()}
    }

    ${belowTablet`
        grid-area: checkbox;
    `}
`

export const AvatarCell = styled.div`
    padding-top: 4px;

    ${belowTablet`
        grid-area: checkbox;
    `}
`

export const TaskCell = styled(Columns)`
    flex-direction: column;
    align-items: flex-start;
    min-width: 0;

    ${belowTablet`
        padding-inline-end: 24px;
    `}
`

export const TaskHeading = styled.div`
    max-width: 100%;
`

export const ActionButton = styled(Naked)`
    max-width: 100%;
    text-align: left;

    &:where(:hover, :focus-visible) {
        text-decoration: underline;
    }

    &:focus-visible {
        border-radius: 4px;
        outline: 2px solid var(--huma-color-border-default-focus);
        outline-offset: 1px;
    }
`

export const Title = styled(H3)`
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    margin: 0;

    text-align: left;

    &.done {
        text-decoration: line-through;
    }
`

export const Description = styled(Caption)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const Meta = styled(CaptionSmall)`
    line-height: 24px;

    ${belowTablet`
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        line-height: 20px;

        &:first-of-type {
            margin-top: 8px;
        }
    `}

    span {
        color: var(--huma-color-foreground-default);
    }
`

export const DotColumns = styled.span`
    display: inline-grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 4px;
    justify-content: start;
    align-items: center;
`

export const PlainSmall = styled(Plain)`
    height: 24px;
    font-size: 14px;
`

export const AssignedCell = styled.div`
    ${belowTablet`
        grid-area: assigned;
        grid-column-end: 2;
    `}
`

export const Entities = styled(TriggerTexts)`
    padding: 2px 0;
    color: var(--huma-color-foreground-default);

    span, a {
        color: var(--huma-color-foreground-default);

        &:focus,
        &:hover {
            color: var(--huma-color-foreground-default);
        }
    }
`

export const Person = styled(PersonBase)`
    height: 24px;
`

export const DeadlineCell = styled(Columns)`
    flex-direction: column;
    align-items: flex-start;

    ${belowTablet`
        grid-area: status;
        grid-column-end: 4;
    `}

    ${({ theme: { tasks: { context } } }) => {
        if(context === 'dashboard') {
            return fromTablet`
                align-items: flex-end;
            `
        }
    }}
`

export const Deadline = styled(Caption)`
    font-size: 14px;

    &.overdue {
        color: var(--huma-color-foreground-destructive);
    }
`

export const ActionsCell = styled(Columns)`
    ${belowTablet`
        position: absolute;
        top: 16px;
        right: 0;
    `}

    .actions-button {
        margin-block: -8px;

        ${belowTablet`
            margin-block: 0;
        `}
    }
`
