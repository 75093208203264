import React, { Component } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useForm } from 'components/form/controller'
import { formatDataTime, formatTime } from 'components/form/field/time'
import { toDate } from 'date-fns-tz'
import { isPast, isFuture } from 'date-fns'
import { prettifyTimeZone } from 'utilities/date-time'
import { capitalize } from 'utilities/string'
import { omit, size } from 'utilities/object'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { Control, MessageRevealer, Message } from './s'
import { Field, Label, Error } from 'components/form/field/s'
import { Plain } from 'components/button'
import Inputs from './inputs'

const empty = {
    date: '',
    time: '',
    zone: ''
}

class EditZonedTime extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(),

        }

        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, picker, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const pickerChanged = !isEqual(picker, this.props.picker)
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged || pickerChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched',
            allowTimeless = false,
            allowZoneless = false
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => {
                const errors = valiDate(value, {
                    ...(this.props.picker ?? null),
                    allowTimeless,
                    required
                })

                if(size(errors)) {
                    return false
                }

                if(required && !value?.date && !value?.time && !value?.zone) {
                    return false
                }

                if(allowTimeless || allowZoneless) {
                    if(!!allowTimeless && !!allowZoneless && !!value?.time && !!value?.zone && !value?.date) {
                        return false
                    }

                    if(required && !value?.date) {
                        return false
                    }

                    if(!allowTimeless && !value?.time) {
                        return false
                    }

                    if(!allowZoneless && !value?.zone) {
                        return false
                    }
                }

                return true
            }
        }, update)
    }

    valueStateFromProps = (props = this.props) => {
        let {
            value,
            required = false,
            allowTimeless = false
        } = props.field ?? {}

        if(!value) {
            value = empty
        }

        return {
            value: this.getDataFormattedTime(value),
            meta: this.getMeta(value),
            errors: valiDate(value, {
                ...(props.picker ?? null),
                allowTimeless,
                required
            })
        }
    }

    getMeta = value => {
        const { dateLocale: locale } = this.props.i18n
        const { formatMessage } = this.props.intl

        return formatTime('zoned', locale, formatMessage)(value)
    }

    getDataFormattedTime = value => {
        if(!value?.date || !value?.time || !value?.zone) {
            return value
        }

        const { mode = 'zoned' } = this.props
        return formatDataTime(mode)(value)
    }

    onChange = value => {
        value = this.getDataFormattedTime(value)
        const meta = this.getMeta(value)

        const {
            required = false,
            allowTimeless = false
        } = this.props.field ?? {}

        const errors = valiDate(value, {
            ...(this.props.picker ?? null),
            allowTimeless,
            required
        })

        this.setState({
            value,
            meta,
            errors
        })

        this.props.onChange?.({ [this.props.name]: value })
        this.props.form.triggerChange(name)
    }

    unset = () => this.setState({ value: empty }, () => {
        this.props.onUnset?.()
        this.props.onChange?.({ [this.props.name]: empty })
        this.props.form.triggerChange(this.props.name)
    })

    render() {
        const {
            value = {},
            meta,
            errors
        } = this.state

        const {
            className,
            label,
            salt,
            name,
            unsettable = false,
            enabled = true,
            loading = false,
            field = {},
            picker = {},
            error: outsideError,
            intl: { formatMessage },
            i18n,
            forwardedRef,
            ...props
        } = this.props

        const { dateLocale: locale } = i18n

        const touched = this.props.form.touched.includes(name)
        const error = ((name in this.props.form.errors) && touched) || !!outsideError

        const controlClassName = cls([
            !!size(errors) && 'error'
        ])

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading'
        ])

        const id = `${salt}:${name}`

        const {
            required = false,
            optional,
            allowTimeless = false
        } = field

        const {
            past = true,
            before = null,
            after = null,
            future = true,
            zoneOnDemand = false
        } = picker

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control {...(controlClassName ? { className: controlClassName } : null)}>
                    <input
                        name={`${name}[date]`}
                        defaultValue={value.date}
                        type="hidden"
                        key={`${id}:input:date:${value.date}`} />
                    <input
                        name={`${name}[time]`}
                        defaultValue={value.time}
                        type="hidden"
                        key={`${id}:input:time:${value.time}`} />
                    <input
                        name={`${name}[zone]`}
                        defaultValue={value.zone}
                        type="hidden"
                        key={`${id}:input:zone:${value.zone}`} />
                    <Inputs
                        {...omit(props, 'form', 'field', 'whistle')}
                        value={value}
                        onChange={this.onChange}
                        zoneOnDemand={zoneOnDemand}
                        enabled={enabled}
                        empty={empty}
                        salt={salt}
                        ref={forwardedRef} />
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
                <MessageRevealer animate={(!!value.time && !past && !!errors.past) ? 'reveal' : 'hide'}>
                    <Message
                        type="error"
                        message={formatMessage({
                            id: 'time_helper_future_required',
                            defaultMessage: 'The point in time you has to be in the future.'
                        })} />
                </MessageRevealer>
                <MessageRevealer animate={(allowTimeless && !value.time && !!before && !!errors.before) ? 'reveal' : 'hide'}>
                    <Message
                        type="error"
                        message={formatMessage({
                            id: 'time_helper_before',
                            defaultMessage: 'The date must be before {date}.'
                        }, {
                            date: formatTime('zoned', locale, formatMessage, { format: 'PPPP' })(before)?.here?.time
                        })} />
                </MessageRevealer>
                <MessageRevealer animate={(!!value.time && !!before && !!errors.before) ? 'reveal' : 'hide'}>
                    <Message
                        type="error"
                        message={formatMessage({
                            id: 'time_helper_before_required',
                            defaultMessage: 'The point in time you pick has to be before {time} in your local time.'
                        }, {
                            time: formatTime('zoned', locale, formatMessage, {
                                format: formatMessage({
                                    id: 'date_fns_format_weekday_date_time_friendly',
                                    defaultMessage: `EEEE, PPP 'at' p`
                                })
                            })(before)?.here?.time
                        })} />
                </MessageRevealer>
                <MessageRevealer animate={(!!value.time && !!after && !!errors.after) ? 'reveal' : 'hide'}>
                    <Message
                        type="error"
                        message={formatMessage({
                            id: 'time_helper_after_required',
                            defaultMessage: 'The point in time you pick has to be after {time} in your local time.'
                        }, {
                            time: formatTime('zoned', locale, formatMessage, {
                                format: formatMessage({
                                    id: 'date_fns_format_weekday_date_time_friendly',
                                    defaultMessage: `EEEE, PPP 'at' p`
                                })
                            })(after)?.here?.time
                        })} />
                </MessageRevealer>
                <MessageRevealer animate={(allowTimeless && !value.time && !!after && !!errors.after) ? 'reveal' : 'hide'}>
                    <Message
                        type="error"
                        message={formatMessage({
                            id: 'time_helper_after',
                            defaultMessage: 'The date must be after {date}.'
                        }, {
                            date: formatTime('zoned', locale, formatMessage, { format: 'PPPP' })(after)?.here?.time
                        })} />
                </MessageRevealer>
                <MessageRevealer animate={(!!value.time && !future && !!errors.future) ? 'reveal' : 'hide'}>
                    <Message
                        type="error"
                        message={formatMessage({
                            id: 'time_helper_past_required',
                            defaultMessage: 'The point in time you pick has to be in the past.'
                        })} />
                </MessageRevealer>
                <MessageRevealer animate={(!size(errors) && !!value.time && !!meta.zoneDifferent && !meta.timeDifferent) ? 'reveal' : 'hide'}>
                    <Message
                        type="info"
                        message={formatMessage({
                            id: 'time_zone_different_time_same',
                            defaultMessage: 'Your local time is currently the same as the time in the time zone you’ve selected. This might change due to e.g. daylight savings.'
                        })} />
                </MessageRevealer>
                <MessageRevealer animate={(!size(errors) && !!value.time && !!meta.zoneDifferent && !!meta.timeDifferent) ? 'reveal' : 'hide'}>
                    <Message
                        type="info"
                        message={capitalize(formatMessage({
                            id: 'time_zone_different_time_different',
                            defaultMessage: '{thereTime} in {thereZone} corresponds to {hereTime} in your local time.'
                        }, {
                        thereTime: meta.there.time,
                        thereZone: prettifyTimeZone(meta.there.zone),
                        hereTime: meta.here.time
                    }))} />
                </MessageRevealer>
                {(!!unsettable && (!!value.date || !!value.time || !!value.zone)) && (
                    <Plain
                        className="destructive"
                        onClick={() => this.unset()}>
                        <FormattedMessage
                            id="action_reset"
                            defaultMessage="Reset" />
                    </Plain>
                )}
            </Field>
        )
    }
}

const valiDate = (value, options = {}) => {
    const errors = {}

    const {
        required = false,
        past = true,
        before = null,
        after = null,
        future = true,
        allowTimeless = false
    } = options

    if(required && !value?.date) {
        errors.date = true
    }

    if(required && !value?.time) {
        errors.time = true
    }

    if(required && !value?.zone) {
        errors.zone = true
    }

    if(size(errors)) {
        return errors
    }

    const isofy = ({ date, time, zone }) => (date && (time || !!allowTimeless) && zone) ?
        (allowTimeless && !time) ?
            toDate(`${date}T00:00:00`, { timeZone: zone })
                : toDate(`${date}T${time}`, { timeZone: zone })
            : null
    const iso = isofy(value)

    // Disallow past dates: Disable if point in time is in the past
    if(!past && iso && isPast(iso)) {
        errors.past = true
    }

    // Disallow points in time after…
    if(
        !!before &&
        iso &&
        (
            (!allowTimeless && isofy(before).toISOString() < iso.toISOString()) ||
            (!!allowTimeless && isofy(before).toISOString() <= iso.toISOString())
        )
    ) {
        errors.before = true
    }

    // Disallow points in time before…
    if(
        !!after &&
        iso &&
        (
            (!allowTimeless && isofy(after).toISOString() > iso.toISOString()) ||
            (!!allowTimeless && isofy(after).toISOString() >= iso.toISOString())
        )
    ) {
        errors.after = true
    }

    // Disallow future dates: Disable if point in time is in the future
    if(!future && iso && isFuture(iso)) {
        errors.future = true
    }

    return errors
}

export default props => {
    const form = useForm()
    const intl = useIntl()
    const i18n = useI18n()

    return (
        <EditZonedTime
            {...props}
            form={form}
            intl={intl}
            i18n={i18n} />
    )
}