import styled, { keyframes, css } from 'styled-components'
import {
    svgStroke, buttonOutlineProps,
    belowTablet, darkMode
} from 'utilities/styled'
import { loaderCss } from 'components/loader'
import Arrow from 'static/gfx/button-arrow.ucsvg'
import { linkCss } from 'components/link'

export const nakedCss = css`
    @layer button {
        user-select: none;
        cursor: pointer;

        outline: none;
        border: none;
        padding: 0;
        background-color: var(--button-background-color, transparent);

        transition: all .1s ease-in-out;

        color: var(--button-text-color, var(--huma-color-foreground-default));

        &:disabled {
            cursor: not-allowed;
        }
    }
`

export const dressedCss = css`
    ${nakedCss}

    @layer button {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 8px;

        border: 1px solid var(--button-border-color, transparent);
        border-radius: 4px;
        height: 40px;
        padding-inline: 16px;

        white-space: nowrap;
        font-size: 16px;
        font-weight: 400;

        &:focus-visible {
            --button-outline-color: var(--huma-color-border-default-focus);
            outline: 2px solid var(--button-outline-color, transparent);
            outline-offset: 1px;
        }

        svg {
            ${svgStroke()}

            scale: 1;
            opacity: 1;
        }

        &.loading {
            pointer-events: none;

            padding-inline-start: 40px;
            padding-inline-end: 8px;

            &::before {
                ${loaderCss}

                content: "";
                left: 20px;

                width: 24px;
            }

            &.medium::before {
                width: 20px;
            }

            &.small::before {
                width: 16px;
            }

            &.narrow {
                &::before {
                    left: 15px;
                }

                &:where(.has-label:not(.reversed, .has-icon)) {
                    padding-inline-start: 32px;
                }
            }

            svg {
                scale: 0;
                opacity: 0;
            }
        }

        &:disabled {
            --button-text-color: var(--huma-color-foreground-on-disabled);
            --button-background-color: var(--huma-color-surface-disabled);
            --button-border-color: var(--huma-color-surface-disabled);

            &::before {
                --__huma-component-loader-color: var(--huma-color-foreground-on-disabled);
            }
        }

        &.centered {
            justify-content: center;
        }

        /* Icon at the start */
        &:where(.has-icon.has-label:not(.reversed)) {
            &.loading {
                padding-inline: 16px;

                &::before {
                    left: 24px;
                }
            }
        }

        /* Icon at the end */
        &:where(.has-icon.has-label.reversed) {
            flex-direction: row-reverse;

            &.loading {
                padding-inline: 16px;

                &::before {
                    inset-block-end: auto;
                    inset-inline: auto 0;
                }
            }
        }

        /* Icon only */
        &:is(.has-icon:not(.has-label)) {
            display: grid;
            place-items: center;
            grid-template: 1fr / 1fr;

            aspect-ratio: 1;
            padding-inline: 0;

            > *,
            &::before {
                grid-area: 1 / 1;
            }

            &.loading {
                padding-inline: 0;

                &::before {
                    position: static;

                    width: 32px;

                    translate: none;
                }

                &.medium::before {
                    width: 20px;
                }

                &.small::before {
                    width: 16px;
                }
            }
        }

        &.text-aligned {
            height: 24px;
        }

        &.medium {
            height: 32px;

            font-size: 14px;
        }

        &.small {
            height: 24px;

            font-size: 14px;
        }

        &.narrow {
            padding-inline: 8px;
        }

        &.compact {
            height: auto;
            line-height: inherit;
        }

        &.inline {
            display: inline-flex;
        }

        &.allow-wrap {
            white-space: normal;
            word-wrap: break-word;
        }
    }
`

export const buttonCss = css`
    ${dressedCss}

    &:not(.constructive, .destructive).loading {
        --__huma-component-loader-color: var(--huma-color-foreground-inverted);
    }

    &:not(:disabled) {
        --__huma-component-loader-color: var(--huma-color-foreground-on-bold);
        --button-border-color: var(--huma-color-border-bold);
        --button-background-color: var(--huma-color-surface-neutral-bold);
        --button-text-color: var(--huma-color-foreground-inverted);

        &.constructive {
            --button-outline-color: var(--huma-color-border-constructive-focus);
            --button-border-color: var(--huma-color-surface-constructive-bold);
            --button-background-color: var(--huma-color-surface-constructive-bold);
            --button-text-color: var(--huma-color-foreground-on-bold);

            &:hover {
                --button-border-color: var(--huma-color-surface-constructive-bold-hover);
                --button-background-color: var(--huma-color-surface-constructive-bold-hover);
            }

            &:active {
                --button-border-color: var(--huma-color-surface-constructive-bold-active);
                --button-background-color: var(--huma-color-surface-constructive-bold-active);
            }
        }

        &.destructive {
            --button-outline-color: var(--huma-color-border-destructive-focus);
            --button-border-color: var(--huma-color-surface-destructive-bold);
            --button-background-color: var(--huma-color-surface-destructive-bold);
            --button-text-color: var(--huma-color-foreground-on-bold);

            &:hover {
                --button-border-color: var(--huma-color-surface-destructive-bold-hover);
                --button-background-color: var(--huma-color-surface-destructive-bold-hover);
            }

            &:active {
                --button-border-color: var(--huma-color-surface-destructive-bold-active);
                --button-background-color: var(--huma-color-surface-destructive-bold-active);
            }
        }
    }
`

export const buttOnDarkCss = css`
    ${dressedCss}

    --__huma-component-loader-color: var(--huma-palette-white);
    --button-outline-color: var(--huma-palette-white);
    --button-background-rgb: var(--huma-palette-white);
    --button-background-alpha: .1;
    --button-background-color-computed: rgb(from var(--button-background-rgb) r g b / var(--button-background-alpha));
    --button-background-color: var(--button-background-color-computed);
    --button-text-color: var(--huma-palette-white);

    &:disabled {
        --button-background-alpha: .05;
        --button-text-color: rgb(from var(--huma-palette-white) r g b / .3);

        border-color: transparent;
    }

    &:not(:disabled) {
        &:hover {
            --button-background-alpha: .2;
        }

        &:is(:active, .active) {
            --button-background-alpha: .3;
        }

        &.constructive {
            --__huma-component-loader-color: var(--huma-palette-blue-40);
            --button-outline-color: var(--huma-palette-blue-80);
            --button-background-alpha: 1;
            --button-text-color: var(--huma-palette-blue-40);

            &:where(:hover, :focus-visible, :active, .active) {
                --button-background-alpha: .9;
            }
        }

        &.destructive {
            --__huma-component-loader-color: var(--huma-palette-red-90);
            --button-outline-color: var(--huma-palette-red-90);
            --button-background-rgb: var(--huma-palette-red-40);
            --button-background-alpha: .75;

            &:where(:hover, :focus-visible, :active, .active) {
                --button-background-alpha: 1;
            }
        }
    }
`

export const benjaminCss = css`
    ${dressedCss}

    &:not(:disabled) {
        --button-outline-color: var(--huma-palette-yellow-70);
        --button-background-color: var(--huma-palette-yellow-60);
        --button-border-color: var(--button-background-color);
        --button-text-color: var(--huma-palette-purple-30);

        &:hover {
            --button-background-color: var(--huma-palette-yellow-50);
        }

        &:is(:active, .active) {
            --button-background-color: var(--huma-palette-yellow-40);
        }
    }
`

export const humaCss = css`
    ${dressedCss}

    &:not(:disabled) {
        --button-outline-color: var(--huma-palette-purple-30);
        --button-border-color: var(--huma-palette-purple-30);
        --button-background-color: var(--huma-palette-purple-30);
        --button-text-color: var(--huma-palette-yellow-60);

        &:where(:hover, :focus-visible, :active, .active) {
            --button-text-color: var(--huma-palette-yellow-50);
        }

        &:is(:active, .active) {
            --button-border-color: var(--huma-palette-purple-20);
            --button-background-color: var(--huma-palette-purple-20);
        }
    }
`

export const ghostCss = css`
    ${dressedCss}

    &:not(:disabled) {
        --button-border-color: var(--huma-color-border-bold);
        --button-text-color: var(--huma-color-foreground-default);

        &:hover {
            --button-background-color: var(--huma-color-surface-neutral-transparent-hover);
        }

        &:active {
            --button-background-color: var(--huma-color-surface-neutral-transparent-active);
        }

        &.constructive {
            --__huma-component-loader-color: var(--huma-color-surface-constructive-bold);
            --button-outline-color: var(--huma-color-border-constructive-focus);
            --button-border-color: var(--huma-color-border-constructive-bold);
            --button-text-color: var(--huma-color-foreground-constructive-bold);

            &:hover {
                --button-background-color: var(--huma-color-surface-constructive-transparent-hover);
            }

            &:active {
                --button-background-color: var(--huma-color-surface-constructive-transparent-active);
            }
        }

        &.destructive {
            --__huma-component-loader-color: var(--huma-color-surface-destructive-bold);
            --button-outline-color: var(--huma-color-border-destructive-focus);
            --button-border-color: var(--huma-color-border-destructive);
            --button-text-color: var(--huma-color-foreground-destructive);

            &:hover {
                --button-background-color: var(--huma-color-surface-destructive-transparent-hover);
            }

            &:active {
                --button-background-color: var(--huma-color-surface-destructive-transparent-active);
            }
        }
    }
`

export const simpleCss = css`
    ${dressedCss}

    @layer button {
        border: none;
    }

    &:not(:disabled) {
        --button-text-color: var(--huma-color-foreground-default);

        &:hover {
            --button-background-color: var(--huma-color-surface-neutral-transparent-hover);
        }

        &:is(:active, .active) {
            --button-background-color: var(--huma-color-surface-neutral-transparent-active);
        }

        &.constructive {
            --__huma-component-loader-color: var(--huma-color-surface-constructive-bold);
            --button-outline-color: var(--huma-color-border-constructive-focus);
            --button-text-color: var(--huma-color-foreground-constructive-bold);

            &:hover {
                --button-background-color: var(--huma-color-surface-constructive-transparent-hover);
            }

            &:is(:active, .active) {
                --button-background-color: var(--huma-color-surface-constructive-transparent-active);
            }
        }

        &.destructive {
            --__huma-component-loader-color: var(--huma-color-surface-destructive-bold);
            --button-outline-color: var(--huma-color-border-destructive-focus);
            --button-text-color: var(--huma-color-foreground-destructive);

            &:hover {
                --button-background-color: var(--huma-color-surface-destructive-transparent-hover);
            }

            &:is(:active, .active) {
                --button-background-color: var(--huma-color-surface-destructive-transparent-active);
            }
        }
    }
`

export const plainCss = css`
    ${dressedCss}

    @layer button {
        border: none;
        padding: 0;
    }

    &:disabled {
        --button-background-color: transparent;
    }

    &:not(:disabled) {
        --button-text-color: var(--huma-color-foreground-default);

        &:where(:hover, :active, .active) {
            text-decoration: underline;
        }

        &.constructive {
            --__huma-component-loader-color: var(--huma-color-surface-constructive-bold);
            --button-outline-color: var(--huma-color-border-constructive-focus);
            --button-text-color: var(--huma-color-foreground-constructive-bold);

            &:is(:active, .active) {
                --button-text-color: var(--huma-color-foreground-constructive-bold-active);
            }
        }

        &.destructive {
            --__huma-component-loader-color: var(--huma-color-surface-destructive-bold);
            --button-outline-color: var(--huma-color-border-destructive-focus);
            --button-text-color: var(--huma-color-foreground-destructive);

            &:is(:active, .active) {
                --button-text-color: var(--huma-color-foreground-destructive-active);
            }
        }
    }
`

export const plainOnDarkCss = css`
    ${dressedCss}

    --button-text-alpha: .85;
    --button-text-color: rgb(from var(--huma-palette-white) r g b / var(--button-text-alpha));
    --button-outline-color: rgb(from var(--huma-palette-white) r g b / .15);

    @layer button {
        border: none;
        padding: 0;
    }

    &:disabled {
        --button-text-alpha: .15;
    }

    &:not(:disabled) {
        &:where(:hover, :active, .active) {
            text-decoration: underline;
        }

        &:is(:active, .active) {
            --button-text-alpha: 1;
        }
    }
`

export const raisedCss = css`
    ${dressedCss}

    @layer button {
        border: none;
    }

    &:not(:disabled) {
        --button-background-color: var(--huma-color-surface-elevated);
        --button-box-shadow: var(--huma-shadow-lower);

        box-shadow: var(--button-box-shadow);

        &:hover {
            --button-background-color: var(--huma-color-surface-elevated-hover);
        }

        &:is(:active, .active) {
            --button-box-shadow: var(--huma-shadow-lowest);

            ${darkMode`
                --button-background-color: var(--huma-color-surface-elevated-active);
            `}
        }

        &.constructive {
            --__huma-component-loader-color: var(--huma-color-surface-constructive-bold);
            --button-outline-color: var(--huma-color-border-constructive-focus);
            --button-text-color: var(--huma-color-foreground-constructive-bold);
        }

        &.destructive {
            --__huma-component-loader-color: var(--huma-color-surface-destructive-bold);
            --button-outline-color: var(--huma-color-border-destructive-focus);
            --button-text-color: var(--huma-color-foreground-destructive);
        }
    }
`

export const filterCss = css`
    ${dressedCss}

    @layer button {
        padding-inline: 16px 40px;

        font-size: 14px;
    }

    &:not(:disabled) {
        --button-outline-color: var(--huma-color-border-constructive-focus);
        --button-background-color: var(--huma-color-surface-minimal);
        --button-text-color: var(--huma-color-foreground-default);

        &:hover {
            --button-background-color: var(--huma-color-surface-minimal-hover);
        }

        &:is(:active, .active) {
            --button-background-color: var(--huma-color-surface-constructive-minimal);
        }
    }

    & > svg {
        position: absolute;
        top: 52.5%;
        right: 12px;

        transform: translateY(-50%);
    }
`

export const StyledNakedButton = styled.button`${nakedCss}`
export const StyledDressedButton = styled.button`${dressedCss}`
export const StyledButton = styled.button`${buttonCss}`
export const StyledOnDarkButton = styled.button`${buttOnDarkCss}`
export const StyledBenjaminButton = styled.button`${benjaminCss}`
export const StyledHumaButton = styled.button`${humaCss}`
export const StyledGhostButton = styled.button`${ghostCss}`
export const StyledSimpleButton = styled.button`${simpleCss}`
export const StyledPlainButton = styled.button`${plainCss}`
export const StyledPlainOnDarkButton = styled.button`${plainOnDarkCss}`
export const StyledRaisedButton = styled.button`${raisedCss}`

export const StyledFilterButton = styled.button`${filterCss}`

export const halfNakedCss = css`
    cursor: pointer;

    display: grid;
    place-items: center;

    border-radius: 8px;
    height: 40px;
    padding: 0 16px;

    transition: background-color .1s ease-in-out;

    color: var(--huma-color-foreground-default);

    &:where(:hover, :focus-visible) {
        transition: background-color .2s ease;

        background-color: var(--huma-color-surface-neutral-transparent-hover);
    }

    svg {
        ${svgStroke()}
    }
`

export const closeButtonCss = ({ offset = 24, hasLabel = false } = {}) => css`
    ${halfNakedCss}

    padding: 0;

    ${hasLabel ?
        css`
            grid-auto-flow: column;
            grid-auto-columns: auto;
            column-gap: 16px;
        ` :
        css`
            position: absolute;
            top: ${offset}px;
            right: ${offset}px;

            width: 40px;
        `
    }

    &:focus-visible {
        outline-color: var(--huma-color-border-default-focus);
        outline-offset: ${buttonOutlineProps.offset}px;
        outline-style: solid;
        outline-width: ${buttonOutlineProps.width}px;
    }
`

const drawArrowLine = keyframes`
    0% {
        stroke-dashoffset: 70;
    }
    20%, 100% {
        stroke-dashoffset: 0;
    }
`

const drawArrowTop = keyframes`
    0%, 10% {
        opacity: 0;

        stroke-dasharray: 0 30;
        stroke-dashoffset: -5;
    }
    15%, 100% {
        opacity: 1;

        stroke-dasharray: 16 30;
        stroke-dashoffset: 0;
    }
`

export const ButtonArrow = styled(Arrow)`
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%) translateY(-10px);

    &.top {
        top: -60px;
        transform: translateX(-50%) translateY(20px) scaleY(-1);
    }

    path {
        animation-delay: 0.5s;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;

        &:first-of-type {
            stroke-dasharray: 70;
            stroke-dashoffset: 70;

            animation-name: ${drawArrowLine};
        }

        &:last-of-type {
            stroke-dasharray: 0 10;
            stroke-dashoffset: -5;

            opacity: 0;
            animation-name: ${drawArrowTop};
        }
    }

    ${belowTablet`
        display: none;
    `}
`

export const InlineButtonNode = styled.span`
    ${linkCss}

    &:where(:disabled, .disabled) {
        cursor: not-allowed;
    }

    &.neutral {
        text-decoration: underline;
    }

    &.medium {
        font-size: 14px;
    }

    &.small {
        font-size: 14px;
    }

    &.narrow {
        padding-inline: 8px;
    }

    &.compact {
        line-height: inherit;
    }

    &:focus-visible {
        outline-color: var(--huma-color-border-default-focus);
        outline-offset: ${buttonOutlineProps.offset}px;
        outline-style: solid;
        outline-width: ${buttonOutlineProps.width}px;

        &.constructive {
            outline-color: var(--huma-color-border-constructive-focus);
        }

        &.destructive {
            outline-color: var(--huma-color-border-destructive-focus);
        }
    }

    &:not(:disabled) {
        &.neutral:where(:hover, :focus-visible, :active) {
            color: var(--huma-color-foreground-constructive-bold-active);
        }

        &.neutral:where(:hover, :active) {
            text-decoration: underline;
        }
    }
`