import styled, { css } from 'styled-components'
import { paragraphCss } from 'components/typography/paragraph'
import { Block, Inline } from 'components/code'
import { variableCss } from 'components/tiptap/s'

export const StylesContainer = styled.div`
    ${[...Array(5).keys()].map(index => css`
        #content & [data-indent="${index + 1}"] {
            margin-inline-start: ${(index + 1) * 24}px;
        }
    `)}
`

export const Paragraph = styled.p`
    &:not([data-child-of="li"]),
    &[data-child-of="li"]:not(:only-child) {
        ${paragraphCss}

        margin: 0 0 24px;
    }
`

export const CodeBlock = styled(Block)`
    margin: 0 0 24px;
`

export const CodeInline = Inline

export const Variable = styled.code`
    ${variableCss}
`

export const Blockquote = styled.blockquote`
    position: relative;

    margin: 0 0 24px;
    padding-inline-start: 16px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        border-radius: 2px;
        width: 4px;
        height: 100%;
        background-color: var(--huma-color-border-default);
    }

    p:last-child {
        margin-block-end: 0;
    }
`

export const Video = styled.div`
    aspect-ratio: 16 / 9;
    margin: 0 0 24px;
    width: 100%;
`

export const Iframe = styled.iframe`
    display: block;

    border: none;
    padding: 0;
`

export const VideoIframe = styled(Iframe)`
    width: 100%;
    height: 100%;
`

export const Emoji = styled.span`
    display: inline-flex;

    vertical-align: middle;

    font-family: "Windows Chromium Flag Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
    font-weight: normal;
    font-size: 1.25em;
    line-height: 1em;
`

export const HorizontalRule = styled.hr`
    position: relative;
    display: block;

    margin: 32px auto;
    border: 0;
    border-radius: 100%;
    width: 4px;
    height: 4px;
    background-color: var(--huma-color-surface-neutral-bold);

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        display: block;

        border-radius: inherit;
        width: inherit;
        height: inherit;
        background-color: inherit;
    }

    &::before {
        inset-inline-start: -12px;
    }

    &::after {
        inset-inline-end: -12px;
    }
`