import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Slice, Fragment } from '@tiptap/pm/model'
import { emojiIndex } from 'emoji-mart'
import emojiRegex from 'emoji-regex-xs'
import { remapName } from './emoji/suggestions/utilities'

const EmojiInputInterceptors = Extension.create({
    name: 'emoji-input-interceptors',

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('emoji-input-interceptors'),
                props: {
                    handleTextInput: (view, from, to, text) => {
                        const [emoji = null] = text.match(regex) ?? []

                        if(emoji) {
                            const emojiNode = view.state.schema.nodes.emoji.create({
                                name: getEmojiShortcode(emoji),
                                emoji
                            })

                            view.dispatch(view.state.tr.insert(from, emojiNode))
                            return true
                        }

                        return false
                    },
                    transformPasted: (slice, view) => {
                        let nodes = []

                        slice.content.forEach(node => {
                            if(!node.content.content.length) {
                                nodes.push(node)
                                return
                            }

                            let inlines = []

                            node.content.content.forEach(node => {
                                const hasEmoji = !!(node.text?.match(regex) ?? []).length
                                if(node.type.name !== 'text' || !hasEmoji) {
                                    inlines.push(node)
                                    return
                                }

                                const texts = node.text.split(regex)
                                const matches = [...node.text.matchAll(regex)]

                                matches.forEach((match, matchIndex) => {
                                    const text = texts[matchIndex]
                                    const [emoji] = match
                                    const name = getEmojiShortcode(emoji)

                                    if(text) {
                                        inlines.push(view.state.schema.text(text))
                                    }

                                    inlines.push(view.state.schema.nodes.emoji.create({
                                        ...(name ? { name } : null),
                                        emoji
                                    }))
                                })
                            })

                            nodes.push(view.state.schema.nodes[node.type.name].create(
                                node.attrs,
                                Fragment.fromArray(inlines)
                            ))
                        })

                        return new Slice(Fragment.fromArray(nodes), slice.openStart, slice.openEnd)
                    }
                }
            })
        ]
    }
})

const regex = emojiRegex()
const emojis = Object.values(emojiIndex.emojis)
const getEmojiShortcode = unicode => remapName(emojis.find(({ native }) => native === unicode)?.id)

export default EmojiInputInterceptors