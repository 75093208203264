
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { safeFormat } from 'utilities/date-time'
import { getShortName, getFullName } from 'utilities/person'
import { getPeopleProfileUrl } from 'utilities/url'
import { cls } from 'utilities/dom'
import { Wrapper, Name } from './s'
import Paragraph from 'components/typography/paragraph'
import Link from 'components/link'
import RelativeTime from 'components/time-relative'

const Meta = ({ type, heading, by, at, link = true, name = 'full', time = 'relative', ...props }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const translation = heading ?? getTranslation(type)

    if(!translation) {
        return null
    }

    const getName = (name === 'full') ?
        getFullName :
        getShortName

    const [User, userProps = null] = (link && !!by?.id) ?
        [Link, {
            to: getPeopleProfileUrl(by),
            target: '_blank'
        }]
        : [Name]

    const dateFormat = formatMessage({
        id: 'date_fns_format_full_friendly',
        defaultMessage: `PPP 'at' p`
    })

    const nameFormatted = by ?
        (typeof by === 'string') ?
            by :
            getName(by) :
        null

    const byClassName = cls([
        'caption compact',
        props.className?.includes('small') && 'small'
    ])

    return (
        <Wrapper {...props}>
            <Paragraph className={byClassName}>
                <FormattedMessage
                    {...translation}
                    values={{
                        user: (
                            <User {...userProps}>
                                {nameFormatted}
                            </User>
                        ),
                        ...translation.values
                    }} />
            </Paragraph>
            {!!at && (
                <Paragraph className="caption small compact">
                    {(time === 'relative') && (
                        <RelativeTime
                            date={at}
                            format={dateFormat}
                            short={false} />
                    )}
                    {(time === 'fixed') && safeFormat(at, dateFormat, { locale })}
                </Paragraph>
            )}
        </Wrapper>
    )
}

const getTranslation = type => ({
    for: {
        id: 'meta_for',
        defaultMessage: 'For {user}'
    },
    approved: {
        id: 'meta_approved_by',
        defaultMessage: 'Approved by {user}'
    },
    canceled: {
        id: 'meta_canceled_by',
        defaultMessage: 'Canceled by {user}'
    },
    completed: {
        id: 'meta_completed_by',
        defaultMessage: 'Completed by {user}'
    },
    created: {
        id: 'meta_created_by',
        defaultMessage: 'Created by {user}'
    },
    createdOn: {
        id: 'meta_created',
        defaultMessage: 'Created'
    },
    owned: {
        id: 'meta_owned_by',
        defaultMessage: 'Owned by {user}'
    },
    provided: {
        id: 'meta_provided_by',
        defaultMessage: 'Provided by {user}'
    },
    registered: {
        id: 'meta_registered_by',
        defaultMessage: 'Registered by {user}'
    },
    rejected:  {
        id: 'meta_rejected_by',
        defaultMessage: 'Rejected by {user}'
    },
    sent: {
        id: 'meta_sent_by',
        defaultMessage: 'Sent by {user}'
    },
    updated: {
        id: 'meta_updated_by',
        defaultMessage: 'Updated by {user}'
    },
    updatedOn: {
        id: 'meta_updated',
        defaultMessage: 'Updated'
    },
    uploaded: {
        id: 'meta_uploaded',
        defaultMessage: 'Uploaded'
    },
    uploadedBy: {
        id: 'meta_uploaded_by',
        defaultMessage: 'Uploaded by {user}'
    },
    withdrawn:  {
        id: 'meta_withdrawn_by',
        defaultMessage: 'Withdrawn by {user}'
    }
})[type]

export default Meta