import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useChart } from 'contexts/chart'
import { map } from 'utilities/object'
import { sharesToRoundedPercentages } from 'utilities/array'
import { cls } from 'utilities/dom'
import { Title, reduceLabels, formatValue, getColor } from 'components/charts'
import { Container, Disc } from 'components/charts/s'
import { DataPoints, DataPoint, Label, Value } from './s'
import Tooltip, { Trigger } from 'components/tooltip'

export const Content = ({ chart, showPercentages = false, colorize = false, setActiveIndex, onClick }) => {
    const { formatMessage } = useIntl()
    const { territories } = useI18n()

    const valueFormatter = formatValue({
        format: chart.valueFormat,
        formatMessage,
        territories
    })

    const labelReducer = reduceLabels({
        formatMessage,
        territories
    })

    const labels = chart.labels.reduce(labelReducer, {})
    const labelsInOrder = Object.values(labels)
    const unspecifiedIndex = Object.keys(labels).indexOf('unspecified')

    const items = map(chart.data, (value, key) => ({
        label: labels[key],
        key,
        value
    }))
        .sort(({ label: one }, { label: two }) => {
            return labelsInOrder.indexOf(one) - labelsInOrder.indexOf(two)
        })

    const percentages = sharesToRoundedPercentages(items.map(({ value }) => value))

    return (
        <DataPoints>
            {items.map(({ label, key, value }, index) => {
                label = label ?? formatMessage({
                    id: 'noun_not_available',
                    defaultMessage: 'N/A'
                })

                value = valueFormatter(value)

                const color = (chart.colors || colorize) ?
                    getColor({
                        theme: chart.colors,
                        index,
                        unspecifiedIndex,
                        total: items.length
                    }) :
                    null

                const helperTranslationKey = `chart_helper_${chart.id}_${key}`
                const helper = formatMessage({
                    id: helperTranslationKey,
                    defaultMessage: helperTranslationKey
                })

                const last = index + 1 === items.length

                const className = cls([
                    setActiveIndex && 'interactive',
                    color && 'colorized',
                    last && 'last',
                    onClick && 'clickable'
                ])

                const valueClassName = cls([
                    !showPercentages && 'brave'
                ])

                const events = setActiveIndex ? {
                    onMouseEnter: () => setActiveIndex(index),
                    onMouseLeave: () => setActiveIndex(null)
                } : null

                return (
                    <DataPoint
                        {...(className ? { className } : null)}
                        {...events}
                        {...(onClick ? { onClick: () => onClick({ key }) } : null)}
                        key={`chart:${chart.id}:${label}`}>
                        {!!color && <Disc style={{ background: color }} />}
                        <Label className="compact">
                            <span>{label}</span>
                            {(helper !== helperTranslationKey) && (
                                <Tooltip content={helper}>
                                    <Trigger />
                                </Tooltip>
                            )}
                        </Label>
                        <Value
                            className={cls([
                                valueClassName,
                                'compact'
                            ])}
                            {...events}>
                            {!!showPercentages && (
                                <FormattedMessage
                                    id="chart_value_format_value_with_percent"
                                    defaultMessage="{value} <thin>({percent} %)</thin>"
                                    values={{
                                        thin: chunks => <span>{chunks}</span>,
                                        value,
                                        percent: percentages[index]
                                    }} />
                            )}
                            {!showPercentages && value}
                        </Value>
                    </DataPoint>
                )
            })}
        </DataPoints>
    )
}

const TableChart = () => {
    const { chart } = useChart()

    if(!chart?.labels?.length) {
        return null
    }

    return (
        <Container>
            <Title id={chart.id} />
            <Content chart={chart} />
        </Container>
    )
}

export default TableChart