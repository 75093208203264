import React from 'react'
import { FormattedMessage } from 'react-intl'
import { List, Item, Good, Bad } from './s'

const Features = ({ features, size = 24, bad: allBad = false, salt, ...props }) => {
    if(!features?.length) {
        return null
    }

    return (
        <List {...props}>
            {features.map(({ bad = false, ...feature }) => {
                const featureBad = bad ?? allBad

                return (
                    <Item key={`${salt}:feature:${feature.id}`}>
                        <span>
                            {!featureBad && <Good size={size} />}
                            {!!featureBad && <Bad size={size} />}
                        </span>
                        <FormattedMessage {...feature} />
                    </Item>
                )
            })}
        </List>
    )
}

export default Features