import React from 'react'
import { useEditorMeta } from '../extensions/utilities'
import { size, map } from 'utilities/object'
import Tooltip from './tooltip'
import { Section, Button } from './s'

const ToolbarBlocks = ({ editor, every, activate, getTrigger, active, tooltipsEnabled, tooltipTarget, salt }) => {
    const meta = useEditorMeta()

    const blocks = {
        ...(every('Blockquote') ? {
            Blockquote: {
                onClick: getTrigger('toggleBlockquote'),
                disabled: !(editor.can().toggleBlockquote?.() ?? true),
                ...activate(active.blockquote)
            }
        } : null)
    }

    if(!size(blocks)) {
        return null
    }

    return (
        <Section>
            {map(blocks, (attributes, name) => {
                const {
                    label,
                    shortcut,
                    icon
                } = meta[name]()

                const key = `${salt}:block:${name}`

                return (
                    <Tooltip
                        content={label}
                        shortcut={shortcut}
                        enabled={tooltipsEnabled}
                        singleton={tooltipTarget}
                        salt={key}
                        key={key}>
                        <Button
                            {...attributes}
                            tabIndex={-1}>
                            {icon}
                        </Button>
                    </Tooltip>
                )
            })}
        </Section>
    )
}

export default ToolbarBlocks