import React, { forwardRef, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { cls } from 'utilities/dom'
import { getFullName } from 'utilities/person'
import { Wrapper, Avatar, AvatarWrapper, NoteWrapper, NoteContent } from './s'
import Tooltip from 'components/tooltip'
import Paragraph from 'components/typography/paragraph'
import { User } from 'styled-icons/feather'
import { Deactivated } from 'components/person/s'

const Note = forwardRef(({ user = null, link = false, hasTooltip = false, fakeUser = false, from = 'start', clamp = false, children, className }, ref) => {
    const { formatMessage } = useIntl()

    className = cls([
        className,
        from
    ])

    const [ContentWrapper, contentProps] = clamp ?
        [Tooltip, {
            content: (
                <Paragraph className="compact small preserve-line-breaks">
                    {children}
                </Paragraph>
            )
        }]
        : [Fragment, null]

    return (
        <Wrapper
            className={className}
            ref={ref}>
            {user && (
                <AvatarWrapper>
                    <Avatar
                        who={user}
                        link={link}
                        hasTooltip={hasTooltip}
                        size={24} />
                    {(user?.status?.active === false) && (
                        <Tooltip
                            content={[
                                getFullName(user),
                                formatMessage({
                                    id: 'person_status_deactivated',
                                    defaultMessage: 'This account has been deactivated'
                                })
                            ].join(' • ')}>
                            <Deactivated size={24} />
                        </Tooltip>
                    )}
                </AvatarWrapper>
            )}
            {(!user && fakeUser) && (
                <AvatarWrapper>
                    <User size={12} />
                </AvatarWrapper>
            )}
            <ContentWrapper {...contentProps}>
                <NoteWrapper>
                    <NoteContent className={cls([
                        'compact',
                        'preserve-line-breaks',
                        clamp && 'clamp'
                    ])}>
                        {children}
                    </NoteContent>
                </NoteWrapper>
            </ContentWrapper>
        </Wrapper>
    )
})

export default Note
