import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import PersonBase from 'components/person'
import { Plain } from 'components/button'
import { Naked } from 'components/drawable-panel'
import Paragraph from 'components/typography/paragraph'
import { Label as LabelBase } from 'components/form/field/s'
import { AlertTriangleFilled } from 'components/feather'

export const Rows = styled(Grid)`
    grid-gap: 16px;

    &:not(:empty) {
        padding-block-start: 16px;
    }
`

export const UserLayout = styled(Grid)`
    grid-auto-rows: min-content;
    grid-template-columns: 40px 136px minmax(auto, 1fr);
    gap: 8px 16px;
    align-items: center;
`

export const Person = styled(PersonBase)`
    grid-column: 1 / 3;
`

export const RemovePersonButton = styled(Plain)`
    grid-column: 2 / 3;
`

export const UnitDrawable = styled(Naked)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`

export const UnitDrawableHeader = styled(Grid)`
    grid-template-columns: 40px 1fr;
    column-gap: 16px;
    align-items: center;

    height: 40px;

    & > svg {
        ${svgStroke}

        place-self: center;
    }

    & > p {
        grid-area: 1 / 2;

        transition: all .1s ease-in-out;

        line-height: 20px;
    }
`

export const Name = styled(Paragraph).attrs({ className: 'compact' })`
    ${UnitDrawableHeader}:not(.expanded) & {
        transform: translateY(-0.5lh);
    }
`

export const Summary = styled(Paragraph).attrs({ className: 'small caption interpoint-divider compact' })`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .label::after {
        content: ':';

        margin-inline-end: 4px;
    }

    svg {
        ${svgStroke('small')}

        transform: translateY(-1px);

        color: var(--huma-color-foreground-default);
    }

    ${UnitDrawableHeader}.expanded & {
        pointer-events: none;

        opacity: 0;
    }

    ${UnitDrawableHeader}:not(.expanded) & {
        transform: translateY(0.5lh);
    }
`

export const UnitDrawableContent = styled(Grid)`
    grid-template-columns: 136px minmax(auto, 1fr);
    align-items: start;
    gap: 8px 16px;

    margin-block: 8px;
    margin-inline-start: 20px;
    border-left: 1px solid var(--huma-color-border-default);
    padding-inline-start: 36px;

    > button {
        justify-self: start;
    }
`

export const Label = styled(LabelBase)`
    padding-inline-end: 0 !important;
`

export const Warning = styled(AlertTriangleFilled)`
    margin-inline-start: 4px;

    color: var(--huma-color-foreground-warning);
`