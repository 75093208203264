import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { get } from 'utilities/object'
import { pruneBy } from 'utilities/array'
import { getFullName } from 'utilities/person'
import { Wrapper, SelectButton } from './s'
import WordList from 'components/word-list'

// This component can be expanded with support for dumb values if needed, for now it supports objects
const QuickSelect = ({ options, values, insertValue, uniqueBy = 'id' }) => {
    const { isItMyOwnId } = useMe()

    const validOptions = pruneBy(options ?? [], uniqueBy)
        .filter(option => !values.find(value => get(option, uniqueBy) === get(value, uniqueBy)))

    if(!validOptions.length) {
        return null
    }

    return (
        <Wrapper>
            <FormattedMessage
                id="action_pick"
                defaultMessage="Pick" />
            <FormattedMessage
                id="sentence_separator"
                defaultMessage=" " />
            <WordList
                words={validOptions.map(({ id }) => id)}
                values={validOptions}
                join="or"
                wordRenderer={(_, value) => {
                    const myself = (value.type === 'user') ?
                        isItMyOwnId(value.id) :
                        false

                    const name = nameFormatters[value?.type]?.(value) ?? value.name

                    return (
                        <SelectButton
                            className="constructive small compact"
                            onClick={() => insertValue(value)}>
                            {!myself && name}
                            {myself && (
                                <FormattedMessage
                                    id="task_assignee_you"
                                    defaultMessage="{firstName} (you)"
                                    values={{ firstName: name }} />
                            )}
                        </SelectButton>
                    )
                }} />
        </Wrapper>
    )
}

const nameFormatters = {
    organization: organization => organization.name,
    team: team => team.name,
    location: location => location.name,
    user: user => getFullName(user)
}

export default QuickSelect