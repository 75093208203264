import React, { forwardRef } from 'react'
import { size, omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import {
    ItemMetaWrapper,
    Symbol, Name,
    Meta
} from './s'
import { Naked } from 'components/button'
import Ellipsify from 'components/ellipsify'
import { ArrowUpCircle as UpgradeHint } from 'styled-icons/feather'

const ItemMeta = forwardRef(({ icon, symbolProps = {}, name, meta, upgradable, ...props }, ref) => {
    if((!size(symbolProps) && !icon) || !name) {
        return null
    }

    let nameText, metaText
    let nameProps = {}
    let metaProps = {}

    if(typeof name === 'string') {
        nameText = name
    } else {
        nameText = name.text
        nameProps = omit(name, 'text')
    }

    if(meta) {
        if(typeof meta === 'string') {
            metaText = meta
        } else {
            metaText = meta.text
            metaProps = omit(meta, 'text')
        }
    }

    const className = cls([
        props.className,
        upgradable && 'upgradable'
    ])

    return (
        <ItemMetaWrapper
            {...props}
            {...(props?.onClick ? { as: Naked } : null)}
            {...(className ? { className } : null)}
            ref={ref}>
            {icon}
            {!!size(symbolProps) && <Symbol {...symbolProps} />}
            <Name {...omit(nameProps, 'ellipsify')}>
                {(typeof name === 'string') && (
                    <Ellipsify
                        {...nameProps?.ellipsify}
                        text={nameText} />
                )}
                {(typeof name !== 'string') && nameText}
            </Name>
            {meta && <Meta {...metaProps}>{metaText}</Meta>}
            {upgradable && (
                <UpgradeHint
                    className="upgrade-hint"
                    size={16} />
            )}
        </ItemMetaWrapper>
    )
})

export default ItemMeta