import React from 'react'
import styled from 'styled-components'
import PersonBase from 'components/person'
import AvatarsBase from 'components/avatars'
import { Names } from './s'

const Person = styled(PersonBase)`
    margin: 8px 0;
`

const Avatars = styled(AvatarsBase)`
    height: 40px;
`

const DisplayPeople = ({ people, displayAs = 'avatars', salt }) => {
    if(!people?.length) {
        return null
    }

    return (
        <>
            {(people.length === 1 && displayAs === 'avatars') && (
                <Person
                    who={people[0]}
                    size={24} />
            )}
            {(people.length > 1 && displayAs === 'avatars') && (
                <Avatars
                    people={people}
                    max={5}
                    salt={`${salt}:display:avatars`} />
            )}
            {(displayAs === 'names') && (
                <Names
                    units={people}
                    salt={`${salt}:display:names`} />
            )}
        </>
    )
}

export default DisplayPeople