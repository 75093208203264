import React, { Component } from 'react'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { Field, Label, Control } from 'components/form/field/s'
import { Flag } from './s'
import CountrySelect from './select'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { empty } from '../'

class EditCountry extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty: empty.value,
            required,
            include,
            unset: this.unset,

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty.value
    })

    unset = () => this.setState({ value: empty })

    onChange = ({ target: { value } }) => {
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })
    }

    render() {
        let { value } = this.state

        if(value) {
            value = value.toUpperCase()
        }

        const {
            className,
            controlProps = {},
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            field = {},
            traditional = false
        } = this.props

        const {
            required,
            softRequired,
            optional
        } = field

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            controlProps.className,
            traditional && 'traditional'
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <Flag>
                        {!!value && getUnicodeFlagIcon(value)}
                        {!value && '🌐'}
                    </Flag>
                    <CountrySelect
                        {...controlProps}
                        {...(controlClassName ? { className: controlClassName } : null)}
                        id={salt}
                        name={name}
                        value={value}
                        onChange={this.onChange}
                        disabled={!enabled} />
                </Control>
            </Field>
        )
    }
}

export default props => {
    const form = useForm()

    return (
        <EditCountry
            {...props}
            form={form} />
    )
}