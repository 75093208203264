import React, { useState, useEffect } from 'react'
import { useAuth } from 'contexts/auth'
import { useSurveyResponse } from 'hooks/survey-response'
import { useSearchParams } from 'react-router-dom'
import SurveyProvider from 'contexts/survey'
import { pick } from 'utilities/object'
import { Scrollable as Modal } from 'modals/generic'
import Hero from './hero'
import Content from './content'

const SurveyRespond = () => {
    const [, setUrlSearchParams] = useSearchParams()
    const [show, setShow] = useState(false)

    const { status } = useAuth()
    const meta = useSurveyResponse()

    useEffect(() => {
        if(status === 'authorized' && !!meta?.runId) {
            setUrlSearchParams({
                intent: 'survey-respond',
                ...pick(meta, 'runId', 'score')
            }, { replace: true })
        } else if(meta) {
            setShow(true)
        }
    }, [status, meta, setUrlSearchParams])

    const dismiss = () => setShow(false)
    const salt = 'survey:respond'

    return (
        <Modal
            show={show}
            dismiss={dismiss}>
            <SurveyProvider>
                <>
                    <Hero />
                    <Content
                        meta={meta}
                        dismiss={dismiss}
                        salt={salt} />
                </>
            </SurveyProvider>
        </Modal>
    )
}

export default SurveyRespond