import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { ListItem } from 'components/list'
import { flexColumnsCss } from 'components/flex'
import UserBase from 'components/person'

export const PersonItem = styled(ListItem)`
    margin: 0 32px 32px;

    ${belowTablet`
        margin: 0 24px 24px;
    `}
`

export const PersonColumns = styled.label`
    ${flexColumnsCss}
    align-items: center;
    height: 40px;

    &:not(.disabled) {
        cursor: pointer;
    }
`

export const User = styled(UserBase)`
    overflow: hidden;
`