// export const format = ({ name, ...item }) => ({
//     ...item,
//     name: name.replace(/-/g, '_')
// })

export const remapName = name => {
    if(name?.startsWith('flag-')) {
        return name.replace(/-/g, '_')
    }

    if(name?.startsWith('male_')) {
        return name.replace(/^male/g, 'man')
    }

    if(name?.startsWith('female_')) {
        return name.replace(/^female/g, 'woman')
    }

    return name
}

export const format = item => ({
    ...item,
    name: remapName(item.name)
})