import React from 'react'
import { StyledIconBase } from '@styled-icons/styled-icon'
import CompanySvg from 'static/gfx/icon/feather/company.csvg'
import TeamSvg from 'static/gfx/icon/feather/team.csvg'
import TeamPlusSvg from 'static/gfx/icon/feather/team-plus.csvg'
import LocationPlusSvg from 'static/gfx/icon/feather/location-plus.csvg'
import IntegrationSvg from 'static/gfx/icon/feather/integration.csvg'
import JubileeSvg from 'static/gfx/icon/feather/jubilee.csvg'
import CalendarTodaySvg from 'static/gfx/icon/feather/calendar-today.csvg'
import ActivityLogSvg from 'static/gfx/icon/feather/activity-log.csvg'
import BullhornSvg from 'static/gfx/icon/feather/bullhorn.csvg'
import SurveySvg from 'static/gfx/icon/feather/survey.csvg'
import CheckCircleSvg from 'static/gfx/icon/feather/check-circle.csvg'
import CheckCircleFilledSvg from 'static/gfx/icon/feather/check-circle-filled.csvg'
import AlertCircleFilledSvg from 'static/gfx/icon/feather/alert-circle-filled.csvg'
import AlertOctagonFilledSvg from 'static/gfx/icon/feather/alert-octagon-filled.csvg'
import AlertTriangleFilledSvg from 'static/gfx/icon/feather/alert-triangle-filled.csvg'
import AlertClipboard from 'static/gfx/icon/feather/alert-clipboard.csvg'
import AlertMessageSquare from 'static/gfx/icon/feather/alert-message-square.csvg'
import InfoCircleFilledSvg from 'static/gfx/icon/feather/info-circle-filled.csvg'
import CrossCircleFilledSvg from 'static/gfx/icon/feather/cross-circle-filled.csvg'
import DashedCircleSvg from 'static/gfx/icon/feather/dashed-circle.csvg'
import SubordinateSvg from 'static/gfx/icon/feather/subordinate.csvg'
import ColumnEditSvg from 'static/gfx/icon/feather/column-edit.csvg'
import MedalGoldSvg from 'static/gfx/icon/feather/medal-gold.csvg'
import MoneyBagSvg from 'static/gfx/icon/feather/money-bag.csvg'
import MoneyBagReturnSvg from 'static/gfx/icon/feather/money-bag-return.csvg'
import UsersPlusSvg from 'static/gfx/icon/feather/users-plus.csvg'
import SignatureSvg from 'static/gfx/icon/feather/signature.csvg'
import AbsenceApprovalSvg from 'static/gfx/icon/feather/absence-approval.csvg'
import AbsenceRegisterSvg from 'static/gfx/icon/feather/absence-register.csvg'
import AbsenceTransferSvg from 'static/gfx/icon/feather/absence-transfer.csvg'
import AbsenceAdjustmentSvg from 'static/gfx/icon/feather/absence-adjustment.csvg'
import MeetingsSvg from 'static/gfx/icon/feather/meetings.csvg'
import MeetingRoundSvg from 'static/gfx/icon/feather/meeting-round.csvg'
import HandbookPinSvg from 'static/gfx/icon/feather/handbook-pin.csvg'
import UpgradeEnterpriseSvg from 'static/gfx/icon/feather/upgrade-enterprise.csvg'
import ChevronUpDownSvg from 'static/gfx/icon/feather/chevron-up-down.csvg'
import CorporationSvg from 'static/gfx/icon/feather/corporation.csvg'
import CompetenceProfilesSvg from 'static/gfx/icon/feather/competence-profiles.csvg'
import AISvg from 'static/gfx/icon/feather/ai.csvg'
import AlignLeftShortSvg from 'static/gfx/icon/feather/align-left-short.csvg'
import UserEditSvg from 'static/gfx/icon/feather/user-edit.csvg'
import TextEditSvg from 'static/gfx/icon/feather/text-edit.csvg'
import HumaDocumentSvg from 'static/gfx/icon/feather/huma-document.csvg'
import HumaDocumentTemplateSvg from 'static/gfx/icon/feather/huma-document-template.csvg'
import SmartDocumentSvg from 'static/gfx/icon/feather/smart-document.csvg'
import SmartDocumentTemplateSvg from 'static/gfx/icon/feather/smart-document-template.csvg'
import SettingsDocumentSvg from 'static/gfx/icon/feather/settings-document.csvg'
import LanguageSvg from 'static/gfx/icon/feather/language.csvg'
import FileRichTextSvg from 'static/gfx/icon/feather/file-rich-text.csvg'
import UnarchiveSvg from 'static/gfx/icon/feather/unarchive.csvg'
import EmojiUnhappySvg from 'static/gfx/icon/feather/emoji-unhappy.csvg'
import EmojiUnhappyFilledSvg from 'static/gfx/icon/feather/emoji-unhappy-filled.csvg'
import EmojiSadSvg from 'static/gfx/icon/feather/emoji-sad.csvg'
import EmojiSadFilledSvg from 'static/gfx/icon/feather/emoji-sad-filled.csvg'
import EmojiSmileSvg from 'static/gfx/icon/feather/emoji-smile.csvg'
import EmojiSmileFilledSvg from 'static/gfx/icon/feather/emoji-smile-filled.csvg'
import EmojiHappySvg from 'static/gfx/icon/feather/emoji-happy.csvg'
import EmojiHappyFilledSvg from 'static/gfx/icon/feather/emoji-happy-filled.csvg'
import AnonymousSvg from 'static/gfx/icon/feather/anonymous.csvg'
import IdentifiedSvg from 'static/gfx/icon/feather/identified.csvg'

export const Custom = ({ svg, size = 24, className, ...props }) => {
    const { props: svgProps } = svg()
    const { children, ...attrs } = svgProps

    return (
        <StyledIconBase
            width={size}
            height={size}
            iconVerticalAlign="middle"
            {...attrs}
            {...props}
            {...(className ? { className } : null)}>
            {children}
        </StyledIconBase>
    )
}

export const Company = ({ strokeWidth = 1.5, ...props }) => (
    <Custom
        {...props}
        strokeWidth={strokeWidth}
        svg={CompanySvg} />
)

export const Team = ({ strokeWidth = 1.5, ...props }) => (
    <Custom
        {...props}
        strokeWidth={strokeWidth}
        svg={TeamSvg} />
)

export const TeamPlus = ({ strokeWidth = 1.5, ...props }) => (
    <Custom
        {...props}
        strokeWidth={strokeWidth}
        svg={TeamPlusSvg} />
)

export const LocationPlus = ({ strokeWidth = 1.5, ...props }) => (
    <Custom
        {...props}
        strokeWidth={strokeWidth}
        svg={LocationPlusSvg} />
)

export const Integration = ({ strokeWidth = 1.5, ...props }) => (
    <Custom
        {...props}
        strokeWidth={strokeWidth}
        svg={IntegrationSvg} />
)

export const Jubilee = ({ strokeWidth = 1.5, ...props }) => (
    <Custom
        {...props}
        strokeWidth={strokeWidth}
        svg={JubileeSvg} />
)

export const CalendarToday = props => (
    <Custom
        {...props}
        svg={CalendarTodaySvg} />
)

export const ActivityLog = props => (
    <Custom
        {...props}
        svg={ActivityLogSvg} />
)

export const Bullhorn = props => (
    <Custom
        {...props}
        svg={BullhornSvg} />
)

export const Survey = props => (
    <Custom
        {...props}
        svg={SurveySvg} />
)

export const CheckCircle = props => (
    <Custom
        {...props}
        svg={CheckCircleSvg} />
)

export const CheckCircleFilled = props => (
    <Custom
        {...props}
        svg={CheckCircleFilledSvg} />
)

export const AlertCircleFilled = props => (
    <Custom
        {...props}
        svg={AlertCircleFilledSvg} />
)

export const AlertOctagonFilled = props => (
    <Custom
        {...props}
        svg={AlertOctagonFilledSvg} />
)

export const AlertTriangleFilled = props => (
    <Custom
        {...props}
        svg={AlertTriangleFilledSvg} />
)

export const InfoCircleFilled = props => (
    <Custom
        {...props}
        svg={InfoCircleFilledSvg} />
)

export const CrossCircleFilled = props => (
    <Custom
        {...props}
        svg={CrossCircleFilledSvg} />
)

export const DashedCircle = props => (
    <Custom
        {...props}
        svg={DashedCircleSvg} />
)

export const Subordinate = props => (
    <Custom
        {...props}
        svg={SubordinateSvg} />
)

export const ColumnEdit = props => (
    <Custom
        {...props}
        svg={ColumnEditSvg} />
)

export const MedalGold = props => (
    <Custom
        {...props}
        svg={MedalGoldSvg} />
)

export const MoneyBag = props => (
    <Custom
        {...props}
        svg={MoneyBagSvg} />
)

export const MoneyBagReturn = props => (
    <Custom
        {...props}
        svg={MoneyBagReturnSvg} />
)

export const UsersPlus = props => (
    <Custom
        {...props}
        svg={UsersPlusSvg} />
)

export const Signature = props => (
    <Custom
        {...props}
        svg={SignatureSvg} />
)

export const AbsenceApproval = props => (
    <Custom
        {...props}
        svg={AbsenceApprovalSvg} />
)

export const AbsenceRegister = props => (
    <Custom
        {...props}
        svg={AbsenceRegisterSvg} />
)

export const AbsenceTransfer = props => (
    <Custom
        {...props}
        svg={AbsenceTransferSvg} />
)

export const AbsenceAdjustment = props => (
    <Custom
        {...props}
        svg={AbsenceAdjustmentSvg} />
)

export const Meetings = props => (
    <Custom
        {...props}
        svg={MeetingsSvg} />
)

export const MeetingRound = props => (
    <Custom
        {...props}
        svg={MeetingRoundSvg} />
)

export const HandbookPin = props => (
    <Custom
        {...props}
        svg={HandbookPinSvg} />
)

export const UpgradeEnterprise = props => (
    <Custom
        {...props}
        svg={UpgradeEnterpriseSvg} />
)

export const ChevronUpDown = props => (
    <Custom
        {...props}
        svg={ChevronUpDownSvg} />
)

export const Corporation = props => (
    <Custom
        {...props}
        svg={CorporationSvg} />
)

export const CompetenceProfiles = props => (
    <Custom
        {...props}
        svg={CompetenceProfilesSvg} />
)

export const Deviation = props => (
    <Custom
        {...props}
        svg={AlertClipboard} />
)

export const Whistleblowing = props => (
    <Custom
        {...props}
        svg={AlertMessageSquare} />
)

export const AI = props => (
    <Custom
        {...props}
        svg={AISvg} />
)

export const AlignLeftShort = props => (
    <Custom
        {...props}
        svg={AlignLeftShortSvg} />
)

export const UserEdit = props => (
    <Custom
        {...props}
        svg={UserEditSvg} />
)

export const TextEdit = props => (
    <Custom
        {...props}
        svg={TextEditSvg} />
)

export const HumaDocument = props => (
    <Custom
        {...props}
        svg={HumaDocumentSvg} />
)

export const HumaDocumentTemplate = props => (
    <Custom
        {...props}
        svg={HumaDocumentTemplateSvg} />
)

export const SmartDocument = props => (
    <Custom
        {...props}
        svg={SmartDocumentSvg} />
)

export const SmartDocumentTemplate = props => (
    <Custom
        {...props}
        svg={SmartDocumentTemplateSvg} />
)

export const SettingsDocument = props => (
    <Custom
        {...props}
        svg={SettingsDocumentSvg} />
)

export const Language = props => (
    <Custom
        {...props}
        svg={LanguageSvg} />
)

export const FileRichText = props => (
    <Custom
        {...props}
        svg={FileRichTextSvg} />
)

export const Unarchive = props => (
    <Custom
        {...props}
        svg={UnarchiveSvg} />
)

export const EmojiUnhappy = props => (
    <Custom
        {...props}
        svg={EmojiUnhappySvg} />
)

export const EmojiUnhappyFilled = props => (
    <Custom
        {...props}
        svg={EmojiUnhappyFilledSvg} />
)

export const EmojiSad = props => (
    <Custom
        {...props}
        svg={EmojiSadSvg} />
)

export const EmojiSadFilled = props => (
    <Custom
        {...props}
        svg={EmojiSadFilledSvg} />
)

export const EmojiSmile = props => (
    <Custom
        {...props}
        svg={EmojiSmileSvg} />
)

export const EmojiSmileFilled = props => (
    <Custom
        {...props}
        svg={EmojiSmileFilledSvg} />
)

export const EmojiHappy = props => (
    <Custom
        {...props}
        svg={EmojiHappySvg} />
)

export const EmojiHappyFilled = props => (
    <Custom
        {...props}
        svg={EmojiHappyFilledSvg} />
)

export const Anonymous = props => (
    <Custom
        {...props}
        svg={AnonymousSvg} />
)

export const Identified = props => (
    <Custom
        {...props}
        svg={IdentifiedSvg} />
)