import React, { useState, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { onClink } from 'utilities/event'
import { getPeopleTeamUrl, getPeopleLocationUrl } from 'utilities/url'
import { compact, pruneBy } from 'utilities/array'
import { cls } from 'utilities/dom'
import { size, omit } from 'utilities/object'
import { getEntityIcon } from 'components/entity'
import { useCaseStatus, useRiskLevel } from 'pages/deviation/utilities'
import { absenceStatusToClassName } from 'pages/absence/components/approval-status/label'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { BlobsContainer, TextsContainer, Symbol } from './s'
import { TextTriggerEntity, TextPlainEntity, BlobTriggerEntity, BlobPlainEntity } from './entity'
import GroupPreviewModal from 'modals/group-preview'

export const TriggerTexts = ({
    entities,
    onClick,
    preview = false,
    showAggregateIcon = false,
    entityNameOptions = {},
    salt,
    ...props
}) => {
    const formatEntityName = useEntityNameFormatter()
    const [previewing, setPreviewing] = useState(null)

    if(!entities.length && !onClick && !preview) {
        return null
    }

    const multipleTypes = pruneBy(entities, 'type').length > 1

    let AggregateIcon = null
    if(showAggregateIcon && !multipleTypes) {
        AggregateIcon = getEntityIcon(entities[0].type)
    }

    return (
        <TextsContainer
            {...props}
            className={cls([
                props.className,
                'caption compact'
            ])}>
            {!!AggregateIcon && (
                <AggregateIcon
                    size={16}
                    className="aggregate-icon" />
            )}
            {compact(entities).map((entity, index) => {
                const { type } = entity
                const getEntityUrl = getEntityUrlByType(type)

                const trigger = {}
                if(preview && ['team', 'location'].includes(type)) {
                    if(typeof getEntityUrl === 'function') {
                        trigger.onClick = onClink(() => setPreviewing(entity))
                        trigger.url = getEntityUrl(entity)
                    } else {
                        trigger.onClick = () => setPreviewing(entity)
                    }
                } else if(typeof onClick === 'function') {
                    trigger.onClick = () => onClick(entity)
                } else if(typeof getEntityUrl === 'function') {
                    trigger.url = getEntityUrl(entity)
                }

                const Entity = !!size(trigger) ? TextTriggerEntity : TextPlainEntity

                return (
                    <Fragment key={`${salt}:${entity.id}`}>
                        <Entity
                            {...trigger}
                            {...entity}
                            name={formatEntityName(entity, entityNameOptions)} />
                        {(index + 1 !== entities.length) && <>, </>}
                    </Fragment>
                )
            })}
            {!!preview && (
                <GroupPreviewModal
                    {...previewing}
                    show={!!previewing}
                    dismiss={() => setPreviewing(null)} />
            )}
        </TextsContainer>
    )
}

export const PlainTexts = ({
    entities,
    showAggregateIcon = false,
    entityNameOptions = {},
    salt,
    ...props
}) => {
    const formatEntityName = useEntityNameFormatter()

    if(!entities.length) {
        return null
    }

    const multipleTypes = pruneBy(entities, 'type').length > 1

    let AggregateIcon = null
    if(showAggregateIcon && !multipleTypes) {
        AggregateIcon = getEntityIcon(entities[0].type)
    }

    return (
        <TextsContainer {...props}>
            {!!AggregateIcon && (
                <AggregateIcon
                    size={16}
                    className="aggregate-icon" />
            )}
            {entities.map((entity, index) => (
                <Fragment key={`${salt}:${entity.id}`}>
                    <TextPlainEntity
                        {...omit(entity, 'key')}
                        name={formatEntityName(entity, entityNameOptions)} />
                    {(index + 1 !== entities.length) && <>, </>}
                </Fragment>
            ))}
        </TextsContainer>
    )
}

export const TriggerBlobs = ({
    entities,
    onClick,
    preview = false,
    overrideType,
    entityNameOptions = {},
    salt,
    type: commonType,
    ...props
}) => {
    const formatEntityName = useEntityNameFormatter()
    const [previewing, setPreviewing] = useState(null)

    if(!entities.length && !onClick && !preview) {
        return null
    }

    return (
        <BlobsContainer {...props}>
            {compact(entities).map(entity => {
                const { symbol } = entity
                const type = overrideType ?? entity.type ?? commonType
                entity.type = type

                const Icon = getEntityIcon(type)
                const getEntityUrl = getEntityUrlByType(type)

                const trigger = {}
                if(preview && ['team', 'location'].includes(type)) {
                    trigger.onClick = () => setPreviewing(entity)
                } else if(typeof onClick === 'function') {
                    trigger.onClick = onClick(entity)
                } else if(typeof getEntityUrl === 'function') {
                    trigger.url = getEntityUrl(entity)
                }

                return (
                    <Fragment key={`${salt}:${entity.id}`}>
                        <BlobTriggerEntity
                            {...trigger}
                            {...entity}
                            name={formatEntityName(entity, entityNameOptions)}>
                            {!!symbol && (
                                <Symbol symbol={entity.symbol} />
                            )}
                            {(!symbol && !!Icon) && (
                                <Icon size={16} />
                            )}
                        </BlobTriggerEntity>
                    </Fragment>
                )
            })}
            {!!preview && (
                <GroupPreviewModal
                    {...previewing}
                    show={!!previewing}
                    dismiss={() => setPreviewing(null)} />
            )}
        </BlobsContainer>
    )
}

export const PlainBlobs = ({
    entities,
    salt,
    type: commonType,
    overrideType,
    entityNameOptions = {},
    ...props
}) => {
    const formatEntityName = useEntityNameFormatter()
    const deviationCaseStatus = useCaseStatus()
    const deviationRiskLevel = useRiskLevel()

    if(!entities.length) {
        return null
    }

    return (
        <BlobsContainer {...props}>
            {compact(entities).map(entity => {
                entity = { ...entity }

                const { symbol } = entity
                const type = overrideType ?? entity.type ?? commonType
                entity.type = type

                let Icon = getEntityIcon(type)

                if(type === 'deviationSeverity') {
                    entity.className = `status ${deviationRiskLevel(entity.id).className}`
                    Icon = deviationRiskLevel(entity.id).icon
                }

                if(type === 'deviationStatus') {
                    entity.className = `status ${deviationCaseStatus(entity.id).className}`
                }

                if(type === 'absenceStatus') {
                    entity.className = `status ${absenceStatusToClassName(entity.id)}`
                }

                return (
                    <BlobPlainEntity
                        {...entity}
                        name={formatEntityName(entity, entityNameOptions)}
                        key={`${salt}:${entity.id}`}>
                        {!!symbol && (
                            <Symbol symbol={entity.symbol} />
                        )}
                        {(!symbol && !!Icon) && (
                            <Icon size={16} />
                        )}
                    </BlobPlainEntity>
                )
            })}
        </BlobsContainer>
    )
}

const getEntityUrlByType = type => ({
    team: getPeopleTeamUrl,
    location: getPeopleLocationUrl
})[type] ?? null

const useEntityNameFormatter = () => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    return (entity, options = {}) => {
        const { type, name } = entity

        if(type === 'organization') {
            if(name && options.showName) {
                return name
            }

            return formatMessage({
                id: 'noun_everyone',
                defaultMessage: 'Everyone'
            })
        }

        if(type === 'absenceType') {
            return formatMessage({
                id: `absence_type_${name}`,
                defaultMessage: name
            })
        }

        if(type === 'competenceType') {
            return categoryFormatter(entity)
        }

        return name
    }
}