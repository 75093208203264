import React, { useRef, useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { getTimeZones } from '@vvo/tzdb'
import { capitalize } from 'utilities/string'
import { getDate, prettifyTimeZone, safeFormat } from 'utilities/date-time'
import { Rows, Field, DateAndTime, Time, Select } from './s'
import { FlexChildShrink } from 'components/flex'
import Toggler from 'components/form/input/toggler'
import TimePicker from 'modals/time-picker'
import WordList from 'components/word-list'
import Paragraph from 'components/typography/paragraph'
import { InlineButton } from 'components/button'

const Inputs = ({ value, zoneOnDemand, enabled, empty, salt, ...props }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const toggler = useRef()
    const timezone = useRef()

    const currentDate = new Date()

    const [values, setValues] = useState({
        date: value?.date ?
            getDate(value.date, { timeZone: value.zone })
            : currentDate,
        zone: value?.zone ?? currentTimeZone,
        time: value?.time
    })

    const [showZone, setShowZone] = useState(!zoneOnDemand || values.zone !== currentTimeZone)
    const [pickingDate, setPickingDate] = useState(false)
    const [touched, setTouched] = useState(false)
    const [continents, setContinents] = useState([])

    const reset = () => {
        setValues({
            ...empty,
            zone: currentTimeZone
        })
    }

    useEffect(() => {
        const currentContinentCode = getMetaByTimeZone(currentTimeZone).continentCode

        const continents = [
            currentContinentCode,
            ...Object.keys(continentsPrioritized).filter(continentCode => continentCode !== currentContinentCode)
        ].map(continentCode => ({
            continentCode,
            label: formatMessage(continentsPrioritized[continentCode]),
            timeZones: timeZones.filter(timeZone => continentCode === timeZone.continentCode)
        }))

        setContinents(continents)
    }, [])

    useEffect(() => {
        touched && props.onChange?.(values)
    }, [touched, values])

    useEffect(() => {
        if(!value?.date && !value?.time && !value?.zone) {
            reset()
        }
    }, [value])

    return (
        <Rows>
            <FlexChildShrink>
                <DateAndTime>
                    <Field className="compact">
                        <Toggler
                            onClick={() => setPickingDate(!pickingDate)}
                            active={pickingDate}
                            type="date"
                            disabled={!enabled}
                            ref={toggler}>
                            {!!values?.date && capitalize(safeFormat(
                                values.date,
                                formatMessage({
                                    id: 'date_fns_format_weekday_date_short_friendly',
                                    defaultMessage: 'EEEE PP'
                                }),
                                { locale }
                            ))}
                        </Toggler>
                        <TimePicker
                            show={pickingDate}
                            mode="date"
                            salt={salt}
                            time={values?.date}
                            dismiss={() => setPickingDate(false)}
                            cancelAction={() => ({
                                label: formatMessage({
                                    id: 'action_cancel',
                                    defaultMessage: 'Cancel'
                                }),
                                effect: 'neutral',
                                onClick: () => setPickingDate(false)
                            })}
                            doneAction={({ picked }) => ({
                                label: formatMessage({
                                    id: 'action_done',
                                    defaultMessage: 'Done'
                                }),
                                effect: 'constructive',
                                onClick: () => {
                                    setValues(previous => ({
                                        ...previous,
                                        date: getDate(picked, { timeZone: values.zone })
                                    }))
                                    setTouched(true)
                                }
                            })}
                            key={`${salt}:picker:${values.date}`} />
                    </Field>
                    <Field className="compact">
                        <Time
                            {...props}
                            id={`${salt}:date`}
                            type="time"
                            value={values?.time}
                            disabled={!enabled}
                            onChange={e => {
                                setValues(previous => ({
                                    ...previous,
                                    time: e.target.value
                                }))
                                setTouched(true)
                            }} />
                    </Field>
                </DateAndTime>
            </FlexChildShrink>
            <FlexChildShrink>
                {showZone && (
                    <Field className="compact">
                        <Select
                            {...props}
                            id={salt}
                            value={values?.zone}
                            disabled={!enabled}
                            onChange={e => {
                                setValues(previous => ({
                                    ...previous,
                                    zone: e.target.value
                                }))

                                setTouched(true)
                            }}
                            ref={timezone}>
                            {continents.map(({ label, continentCode, timeZones }) => (
                                <optgroup
                                    label={label}
                                    key={`${salt}:continent:${continentCode}`}>
                                    {timeZones.map(({ name, abbreviation: code, mainCities }) => (
                                        <option
                                            value={name}
                                            key={`${salt}:time-zone:${name}`}>
                                            <FormattedMessage
                                                id="timezone_code_cities"
                                                defaultMessage="{timezone} ({code}) – {cities}"
                                                values={{
                                                    timezone: prettifyTimeZone(name),
                                                    code,
                                                    cities: (
                                                        <WordList
                                                            words={mainCities}
                                                            key={`${salt}:time-zone:${name}:main-cities:${mainCities.join('-')}`} />
                                                    )
                                                }} />
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Select>
                    </Field>
                )}
                {!showZone && (
                    <Paragraph className="caption compact small interpoint-divider">
                        <span>
                            {prettifyTimeZone(values.zone)}
                        </span>
                        <InlineButton
                            className="constructive compact small"
                            onClick={() => {
                                setShowZone(true)
                                global.requestAnimationFrame(() => timezone.current?.focus())
                            }}>
                            <FormattedMessage
                                id="time_zone_action_set"
                                defaultMessage="Set time zone" />
                        </InlineButton>
                    </Paragraph>
                )}
            </FlexChildShrink>
        </Rows>
    )
}

const timeZones = getTimeZones()
const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const continentsPrioritized = {
    EU: {
        id: 'continent_name_eu',
        defaultMessage: 'Europe'
    },
    NA: {
        id: 'continent_name_na',
        defaultMessage: 'North America'
    },
    AS: {
        id: 'continent_name_as',
        defaultMessage: 'Asia'
    },
    OC: {
        id: 'continent_name_oc',
        defaultMessage: 'Oceania'
    },
    AF: {
        id: 'continent_name_af',
        defaultMessage: 'Africa'
    },
    SA: {
        id: 'continent_name_sa',
        defaultMessage: 'South America'
    },
    AN: {
        id: 'continent_name_an',
        defaultMessage: 'Antarctica'
    }
}

const getMetaByTimeZone = timeZone => timeZones.find(({ name, group }) =>
    timeZone === name || group.includes(timeZone)
)

export default Inputs