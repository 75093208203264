import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { FlexChildGrow } from 'components/flex'

export const Wrapper = styled.div`
    max-height: calc(60vh - 120px);
    overflow-y: auto;
    margin: 0 -32px;

    &.no-scroll {
        max-height: unset;
        overflow-y: unset;
    }

    ${belowTablet`
        margin: 0 -24px;
    `}
`

export const ContentRow = styled(FlexChildGrow)`
    position: relative;
`

export const LoadingContainer = styled.div`
    position: relative;
    height: 72px;
`