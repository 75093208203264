import React, { forwardRef } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { get } from 'utilities/object'
import paths from 'app/paths'
import { Link as RouterLink } from 'react-router-dom'
import { ExternalLink as ExternalIcon } from 'styled-icons/feather'

export const pulsate = keyframes`
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }
`

export const linkCss = css`
    &,
    &.neutral {
        color: var(--huma-color-foreground-default);

        &.disabled {
            color: var(--huma-color-foreground-disabled);
            pointer-events: none;

            &[role="button"] {
                pointer-events: auto;
            }
        }
    }

    &.constructive {
        color: var(--huma-color-foreground-link);

        &:focus-visible {
            outline: 2px solid var(--huma-color-border-constructive-focus);
        }

        &.disabled {
            color: var(--huma-color-foreground-disabled);
        }
    }

    &.destructive {
        color: var(--huma-color-foreground-destructive);

        &:focus-visible {
            outline: 2px solid var(--huma-color-border-destructive-focus);
        }

        &.disabled {
            color: var(--huma-color-foreground-disabled);
        }
    }

    &.loading {
        animation: ${pulsate} 1s ease-in-out infinite;
        pointer-events: none;
    }

    cursor: pointer;

    text-decoration: none;

    &:where(:hover, :focus-visible) {
        text-decoration: underline;
    }

    &:focus-visible {
        border-radius: 4px;
        outline: 2px solid var(--huma-color-border-default-focus);
        outline-offset: 1px;
    }

    @media (prefers-reduced-motion: no-preference) {
        &:focus-visible {
            transition: outline-offset 75ms ease-out;
        }

        &:not(:active):focus-visible {
            transition-duration: 0.1s;
        }
    }

    &.bold {
        font-weight: 700;
    }

    &.underline-reversed {
        text-decoration: underline;

        &:hover,
        &:focus-visible {
            text-decoration: none;
        }
    }
`

export const externalLinkCss = css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    width: 100%;
    padding: 16px;

    svg {
        ${svgStroke()}
    }
`

// eslint-disable-next-line no-unused-vars
const ExternalAnchor = styled.a.attrs(({ to, theme, ...attrs }) => ({
    ...attrs,
    ...(!!to ? {
        as: RouterLink,
        to
    } : null)
}))`
    ${externalLinkCss}
`

export const ExternalLink = ({ icon = null, text, children, ...props }) => (
    <ExternalAnchor {...props}>
        {icon}
        <span>{text ?? children}</span>
        <ExternalIcon size={20} />
    </ExternalAnchor>
)

// Unpack theme to prevent it from being set as an attribute
// eslint-disable-next-line no-unused-vars
const anchorAttrs = ({ to, theme, ...attrs }) => {
    if(typeof to === 'string' && to?.startsWith('path:')) {
        to = get(paths, to.replace('path:', ''))
    }

    return {
        ...attrs,
        ...(!!to ? {
            as: RouterLink,
            to
        } : null)
    }
}


const Anchor = styled.a.attrs(anchorAttrs)`
    ${linkCss}
`

const NakedAnchor = styled.a.attrs(anchorAttrs)`
    text-decoration: none;
    cursor: pointer;
`

const Link = forwardRef(({ text, children, ...props }, ref) => (
    <Anchor
        {...props}
        {...(ref ? { ref } : null)}>
        {text ?? children}
    </Anchor>
))

export const NakedLink = forwardRef(({ text, children, ...props }, ref) => (
    <NakedAnchor
        {...props}
        {...(ref ? { ref } : null)}>
        {text ?? children}
    </NakedAnchor>
))

export default Link