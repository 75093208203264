import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Naked } from 'components/button'
import { fontSans } from 's'
import Paragraph from 'components/typography/paragraph'

export const Label = styled(Naked)`
    position: relative;

    display: inline-grid;
    align-items: center;
    justify-self: start;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    column-gap: 4px;

    border-radius: 4px;
    height: 24px;
    padding: 0 8px;
    background-color: var(--huma-color-surface-warning-minimal);

    ${fontSans}
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: var(--huma-color-foreground-default);

    &.clickable:where(:hover, :focus) {
        color: var(--huma-color-foreground-constructive-bold);
    }

    svg {
        ${svgStroke('small')}
    }
`

export const Text = styled.span`
    display: inline-flex;
    padding-top: 1px;
`

export const Icon = styled.span`
    display: inline-flex;

    width: 16px;
    height: 16px;
`

export const Content = styled(Paragraph)`
    white-space: pre-wrap;
`