import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSurvey } from 'contexts/survey'
import SurveyResultMessage from './result-message'
import Form from 'components/form/controller'
import RatingField from 'components/form/field/rating'
import TextField from 'components/form/field/text'
import { Actions, Message } from './s'
import { ButtonSubmit, Plain } from 'components/button'
import { Activity as Pulse } from 'styled-icons/feather'
import { Anonymous } from 'components/feather'

const SurveyRespondContent = ({ meta, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const { respond } = useSurvey()

    const {
        jwt,
        type,
        score: emailScore,
        organizationName,
        status: runResponseStatus,
        error
    } = meta

    const [responding, setResponding] = useState(false)
    const [result, setResult] = useState(error ? { error } : null)

    const submit = async body => {
        setResponding(true)

        const {
            ok,
            response,
            status
        } = await respond({
            ...body,
            type
        }, jwt)

        setResponding(false)

        if(ok) {
            setResult({ success: true })
        } else {
            setResult({
                interacted: true,
                error: {
                    code: response?.errorCode ?? response?.error ?? `http_${status}`,
                    message: response?.errorMessage ?? response?.error_description ?? 'Unknown Error'
                }
            })
        }
    }

    const irreparableError = !!result?.error && !result?.interacted
    const actionable = !result?.success && !irreparableError && runResponseStatus === 'OPEN'

    return (
        <>
            <SurveyResultMessage
                {...result}
                status={runResponseStatus} />
            {actionable && (
                <>
                    <Form
                        layout="vertical"
                        onSubmit={submit}>
                        {({ trigger, values }) => (
                            <>
                                <RatingField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'employee_satisfaction_scale_enps_question',
                                        defaultMessage: 'On a scale from zero to ten, how likely is it that you would recommend {orgName} as a place to work?'
                                    }, { orgName: organizationName })}
                                    name="score"
                                    field={{
                                        value: Number(emailScore),
                                        required: true,
                                        unsettable: false,
                                        min: 0,
                                        max: 10,
                                        include: 'always'
                                    }}
                                    controlProps={{ autoFocus: true }}
                                    optionsClassName="spread" />
                                <TextField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'employee_satisfaction_survey_label_tell_more',
                                        defaultMessage: 'Do you want to tell us a bit more about why you’re giving {organizationName} a score of {score}?'
                                    }, {
                                        organizationName,
                                        score: values.score ?? emailScore
                                    })}
                                    name="comment"
                                    field={{ include: 'touched' }} />
                                <Actions>
                                    <Plain onClick={dismiss}>
                                        <FormattedMessage
                                            id="action_cancel"
                                            defaultMessage="Cancel" />
                                    </Plain>
                                    <ButtonSubmit
                                        className={`constructive${responding ? ' loading' : ''}`}
                                        disabled={responding}
                                        ref={trigger}>
                                        <FormattedMessage
                                            id="action_submit"
                                            defaultMessage="Submit" />
                                    </ButtonSubmit>
                                </Actions>
                            </>
                        )}
                    </Form>
                    <Message
                        type="neutral"
                        message={formatMessage({
                            id: 'employee_satisfaction_survey_respond_message_pulse',
                            defaultMessage: 'This survey will give {organization} a pulse on the satisfaction of the company and help them give you as good a workplace as possible.'
                        }, { organization: organizationName })}
                        icon={Pulse} />
                    <Message
                        type="neutral"
                        className="compact"
                        message={formatMessage({
                            id: 'survey_responses_user_anonymous',
                            defaultMessage: 'Responses will not be linked to you'
                        })}
                        icon={Anonymous} />
                </>
            )}
            {!actionable && (
                <Actions className="compact centered">
                    <Plain onClick={dismiss}>
                        <FormattedMessage
                            id="action_close"
                            defaultMessage="Close" />
                    </Plain>
                </Actions>
            )}
        </>
    )
}

export default SurveyRespondContent