import React from 'react'
import { useEditorMeta } from '../extensions/utilities'
import { size, map } from 'utilities/object'
import Tooltip from './tooltip'
import { Section, Button } from './s'

const ToolbarLists = ({ editor, every, activate, getTrigger, getCommand, active, tooltipsEnabled, tooltipTarget, salt }) => {
    const meta = useEditorMeta()

    const lists = {}

    if(every('BulletList', 'ListItem') && !active.orderedList) {
        lists.BulletList = {
            onClick: getTrigger('toggleBulletList'),
            disabled: !(editor.can().toggleBulletList?.() ?? true),
            ...activate(active.bulletList)
        }
    }

    if([
        every('BulletList', 'OrderedList', 'ListItem'),
        active.orderedList && active.listItem,
        !(editor.can().toggleBulletList?.() ?? true)
    ].every(Boolean)) {
        lists.BulletConvert = {
            onClick: getCommand('toggleList', 'bulletList', 'listItem')
        }
    }

    if(every('OrderedList', 'ListItem') && !active.bulletList) {
        lists.OrderedList = {
            onClick: getTrigger('toggleOrderedList'),
            disabled: !(editor.can().toggleOrderedList?.() ?? true),
            ...activate(active.orderedList)
        }
    }

    if([
        every('BulletList', 'OrderedList', 'ListItem'),
        active.bulletList && active.listItem,
        !(editor.can().toggleOrderedList?.() ?? true)
    ].every(Boolean)) {
        lists.OrderedConvert = {
            onClick: getCommand('toggleList', 'orderedList', 'listItem')
        }
    }

    if(!size(lists)) {
        return null
    }

    return (
        <Section>
            {map(lists, (attributes, name) => {
                const {
                    label,
                    tooltip,
                    icon
                } = meta[name]()

                const key = `${salt}:list:${name}`

                return (
                    <Tooltip
                        content={label}
                        tooltip={tooltip}
                        enabled={tooltipsEnabled}
                        singleton={tooltipTarget}
                        salt={key}
                        key={key}>
                        <Button
                            {...attributes}
                            tabIndex={-1}>
                            {icon}
                        </Button>
                    </Tooltip>
                )
            })}
        </Section>
    )
}

export default ToolbarLists