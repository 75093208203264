import styled, { css } from 'styled-components'
import { symbolHoverCss } from 'utilities/styled'
import { motion } from 'framer-motion'

export const ColorWrapper = styled.div`
    position: relative;
    z-index: 1;
    isolation: isolate;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--symbol-size);
    height: var(--symbol-size);
    font-size: calc(var(--symbol-size) * 0.45);
    user-select: none;

    &.inline {
        display: inline-flex;
    }

    span {
        position: relative;
        z-index: 1;
        text-decoration: none;
    }

    svg {
        position: relative;
        z-index: 1;

        margin: 0 !important;
        width: calc(var(--symbol-size) * 0.45);
        height: calc(var(--symbol-size) * 0.45);
    }
`

export const EmojiWrapper = styled.b`
    font-family: "Windows Chromium Flag Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
    font-weight: normal;
`

const dashedBackground = css`
    &::before {
        border: 2px solid var(--huma-color-surface-minimal);
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;

        border-radius: 50%;
        border: 1px dashed var(--huma-color-border-subtle);
    }
`

export const Background = styled.div`
    position: absolute;

    border-radius: 50%;
    width: inherit;
    height: inherit;
    background: conic-gradient(
        var(--symbol-color-token, var(--symbol-color)) var(--symbol-angle),
        transparent var(--symbol-angle)
    );

    transition: background-color .15s ease-in-out;

    color: transparent;

    ${ColorWrapper}.hover-effect & {
        transition-property: background-color, filter;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -1;

            border-radius: 50%;
            border: 2px solid var(--symbol-color-token, var(--symbol-color));
            width: 100%;
            height: 100%;

            opacity: 0;
            transform: scale(1);
            transform-origin: center;
            transition-property: opacity, transform;
            transition-duration: .1s, .2s;
            transition-timing-function: cubic-bezier(0, .85, .40, 1.45);
        }
    }

    ${ColorWrapper}.dashed & {
        ${dashedBackground}

        background: transparent;
    }

    ${ColorWrapper}.passive & {
        ${dashedBackground}

        background: conic-gradient(
            var(--huma-color-surface-minimal) var(--symbol-angle),
            transparent var(--symbol-angle)
        );
    }

    ${ColorWrapper}.voided & {
        background: repeating-linear-gradient(
            -45deg,
            var(--huma-color-surface-minimal),
            var(--huma-color-surface-minimal) 4px,
            var(--huma-color-surface-subtle) 4px,
            var(--huma-color-surface-subtle) 6px
        );

        &::before {
            border-color: var(--huma-color-surface-subtle);
        }
    }
`

export const hoverEffect = css`
    ${symbolHoverCss}

    &::before {
        opacity: 1;
        transform: scale(1.2);
    }
`

export const ancestorHover = Ancestor => css`
    ${Ancestor}:is(:hover, :focus-within) & ${Background} {
        ${hoverEffect}
    }
`

export const siblingHover = Sibling => css`
    ${Sibling}:hover ~ & ${Background},
    ${Sibling}:focus ~ & ${Background},
    &:has(~ ${Sibling}:hover) ${Background},
    &:has(~ ${Sibling}:focus) ${Background} {
        ${hoverEffect}
    }
`

export const FaderWrap = styled.div`
    width: 40px;
    height: 40px;
    position: relative;
`

export const Fader = styled(motion.div).attrs(() => ({
    variants: {
        queued: {
            opacity: 0,
            transform: 'translateX(-100%) scale(.67)',
            transition: {
                duration: 0
            }
        },
        in: {
            opacity: 1,
            transform: 'translateX(0%) scale(1)',
            transition: {
                duration: .5
            }
        },
        out: {
            opacity: 0,
            transform: 'translateX(100%) scale(.67)',
            transition: {
                duration: .25
            }
        }
    },
    initial: 'queued',
    exit: 'out'
}))`
    position: absolute;
    top: 0;
    left: 0;
`