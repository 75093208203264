import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { UnorderedList, ListItem as ItemBase } from 'components/list'
import { Check, X } from 'styled-icons/feather'

export const List = styled(UnorderedList)`
    margin-left: 32px;

    &.spacious {
        margin-block-end: 24px;
    }
`

export const Item = styled(ItemBase)`
    position: relative;

    line-height: 1.4;

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }

    span {
        position: absolute;
        left: -48px;

        width: 48px;

        line-height: inherit;
        text-align: center;
    }

    svg {
        ${svgStroke()}
    }
`

export const Good = styled(Check)`
    color: var(--huma-color-foreground-success);
`

export const Bad = styled(X)`
    color: var(--huma-color-foreground-error);
`