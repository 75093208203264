import styled, { css } from 'styled-components'
import { belowPhone } from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase,
    GridColumnHeaderSortable as GridColumnHeaderSortableBase
} from 'components/grid'
import TutorialBase from 'components/tutorial'
import { SkeletonListRow as SkeletonListRowBase, SkeletonCell as SkeletonCellBase } from 'components/skeleton'
import Paragraph from 'components/typography/paragraph'
import { pieceGrid } from './piece/s'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    ${pieceGrid}

    column-gap: 24px;

    border-bottom: 1px solid var(--huma-color-border-default);
    width: 100%;

    ${belowPhone`
        display: none;
    `}
`

const gridColumnHeaderCss = css`
    display: flex;
    align-items: center;

    height: 72px;
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnHeaderSortable = styled(GridColumnHeaderSortableBase)`
    ${gridColumnHeaderCss}
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`

export const Tutorial = styled(TutorialBase)`
    margin-block-start: 80px;

    ${belowPhone`
        margin-top: 40px;
    `}
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    ${pieceGrid}
`

export const SkeletonCell = styled(SkeletonCellBase)`
    justify-content: center;
`

export const EmptyText = styled(Paragraph)`
    margin-block-end: 32px;
`