import styled, { css } from 'styled-components'
import {
    svgStroke,
    print, screen
} from 'utilities/styled'
import { h1Css, h2Css, h3Css, h4Css } from 'components/typography/heading'
import { paragraphCss } from 'components/typography/paragraph'
import { captionSmallCss } from 'components/typography/caption'
import { unorderedListCss, orderedListCss, listItemCss } from 'components/typography/list'
import { preCss, codeCss } from 'components/code/s'
import { markCss } from 'components/typography/mark'
import { Grid } from 'components/grid'
import { Naked, Plain } from 'components/button'

const mute = (variable, alpha = null) => `rgb(from var(${variable}) r g b / ${alpha ?? .4})`

export const tiptapContentCss = css`
    ${screen`
        a {
            overflow-wrap: break-word;
        }
    `}

    ${print`
        .rough-annotation {
            display: none;
        }
    `}
`

export const GenericTiptapContentStyles = styled.div`
    ${tiptapContentCss}
`

const placeholder = css`
    position: relative;

    &::before {
        pointer-events: none;
        content: attr(data-placeholder);
        position: absolute;
        top: 0;
        left: 0;

        color: var(--huma-color-foreground-minimal);
    }
`

export const variableCss = css`
    position: relative;
    z-index: 1;
    isolation: isolate;

    &.suggestion:is(code),
    &:is(code) > .content {
        text-shadow: 0 0 .5px var(--huma-color-foreground-default);
        font-size: 80%;
    }

    &:is(span) > .content {
        font-family: unset;
        font-size: unset;
    }

    &.filled {
        /* Mask out highlights */
        &::before {
            content: "";
            position: absolute;
            inset: 0;

            background-color: var(--huma-color-surface-default);
        }

        > .content {
            background-color: ${mute('--huma-color-surface-success-minimal', .5)};
            box-shadow: inset 0 0 0 .5px var(--huma-color-border-success-focus);

            > .icon {
                background-color: var(--huma-color-surface-success-bold);
            }
        }
    }

    &.incomplete > .content,
    &.empty > .content,
    &.not-set > .content {
        background-color: ${mute('--huma-color-surface-warning-minimal', .5)};
        box-shadow: inset 0 0 0 .5px var(--huma-color-border-warning-focus);

        > .icon {
            background-color: var(--huma-color-surface-warning-bold);
        }
    }

    &.mismatched > .content {
        background: ${mute('--huma-color-surface-constructive-minimal', .5)};
        box-shadow: inset 0 0 0 .5px var(--huma-color-border-constructive-focus);

        > .icon {
            background-color: var(--huma-color-surface-constructive-bold);
        }
    }

    &.detached > .content {
        background: ${mute('--huma-color-surface-brand-minimal', .5)};
        box-shadow: inset 0 0 0 .5px var(--huma-color-border-brand-focus);

        > .icon {
            background-color: var(--huma-color-surface-brand-bold);
        }
    }

    &.inaccessible > .content {
        background: ${mute('--huma-color-surface-destructive-minimal', .5)};
        box-shadow: inset 0 0 0 .5px var(--huma-color-border-destructive-focus);

        > .icon {
            background-color: var(--huma-color-surface-destructive-bold);
        }
    }

    &.suggestion,
    > .content {
        ${codeCss}

        display: inline-flex;
        column-gap: 0.5ch;
        position: relative;

        border: none;
        padding: unset;
        padding-inline-end: 0.5ch;
        box-shadow: inset 0 0 0 .5px var(--huma-color-border-default);

        /* Normalize the influence of marks */
        font-weight: 400;
        font-style: normal;
        text-decoration: none;

        color: var(--huma-color-foreground-default);

        > .icon {
            ${svgStroke('small')}

            display: inline-grid;
            place-items: center;
            flex-shrink: 0;

            border-radius: 4px 0 0 4px;
            width: 20px;
            background-color: var(--huma-color-surface-inverted);

            color: var(--huma-color-foreground-inverted);
        }

        &:has(> .icon) {
            vertical-align: -0.125rem;
            line-height: 1.25rem;
        }

        &:not(:has(> .icon)) {
            text-indent: 0.5ch;
        }
    }
`

export const EditorContentStyles = styled.div`
    &:not(.compact) {
        margin-bottom: 24px;
    }

    &.document [contenteditable="true"] {
        min-height: 360px;
    }

    [contenteditable="true"] {
        min-height: 120px;
        padding: 12px;
        border: 1px solid var(--huma-color-border-default);
        border-radius: 4px;
        transition:
            border-color .1s ease-in-out,
            box-shadow .1s ease-in-out;

        & ::selection {
            background-color: var(--huma-color-surface-subtle);
        }

        &:hover {
            border-color: var(--huma-color-border-default-focus);
        }

        &:focus {
            border-color: var(--huma-color-border-default-focus);
            outline: none;
            box-shadow:
                0 0 0 1px var(--huma-color-surface-default),
                0 0 0 3px var(--huma-color-border-constructive-focus);

            & ::selection {
                background-color: var(--huma-color-surface-constructive-minimal);
            }
        }

        &:invalid,
        &.error {
            border-color: var(--huma-color-border-destructive);

            &:focus {
                box-shadow:
                    0 0 0 1px var(--huma-color-surface-default),
                    0 0 0 3px var(--huma-color-border-destructive-focus);

                & *::selection {
                    background-color: var(--huma-color-surface-destructive-minimal);
                }
            }
        }

        h1 {
            ${h1Css}
        }

        h2 {
            ${h2Css}
        }

        h3 {
            ${h3Css}
        }

        h4 {
            ${h4Css}
        }

        h1.is-empty[data-placeholder]:nth-child(1),
        h2.is-empty[data-placeholder],
        h3.is-empty[data-placeholder],
        h4.is-empty[data-placeholder] {
            ${placeholder}
        }

        p {
            ${paragraphCss}

            &.is-empty[data-placeholder] {
                ${placeholder}
            }
        }

        ul {
            ${unorderedListCss}
        }

        ol {
            ${orderedListCss}
        }

        li {
            ${listItemCss}
        }

        p,
        ul,
        ol {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }

        img {
            display: block;
            max-width: 100%;
            margin-bottom: 24px;
        }

        a {
            color: var(--huma-color-foreground-constructive-bold);
            text-decoration: underline;

            &:where(:hover, :focus) {
                text-decoration: none;
            }
        }

        blockquote {
            position: relative;

            margin: 0 0 24px;
            padding-inline-start: 16px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background-color: var(--huma-color-surface-subtle);
                border-radius: 2px;
            }

            p:last-child {
                margin-bottom: 0;
            }
        }

        pre {
            ${preCss}
            font-size: 14px;
            margin: 0 0 24px;
        }

        code:not(.pre):not(.variable) {
            ${codeCss}
        }

        code:not(.pre).variable,
        span.variable {
            ${variableCss}
        }

        mark {
            ${markCss};
        }

        [data-youtube-video],
        [data-vimeo-video] {
            width: 100%;
            aspect-ratio: 16 / 9;
            margin: 0 0 24px;

            iframe {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0;
            }
        }

        [data-emoji] {
            display: inline-flex;
            font-family: "Windows Chromium Flag Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
            font-weight: normal;
            font-size: 1.25em;
            line-height: 1em;
            vertical-align: middle;
        }

        hr {
            position: relative;
            display: block;

            margin: 32px auto;
            border: 0;
            border-radius: 100%;
            width: 4px;
            height: 4px;
            background-color: var(--huma-color-surface-neutral-bold);

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                display: block;

                border-radius: inherit;
                width: inherit;
                height: inherit;
                background-color: inherit;
            }

            &::before {
                left: -12px;
            }

            &::after {
                right: -12px;
            }
        }

        br.ProseMirror-trailingBreak::selection {
            background-color: transparent;
        }

        ${[...Array(5).keys()].map(index => css`
            [data-indent="${index + 1}"] {
                margin-inline-start: ${(index + 1) * 24}px;
            }
        `)}
    }
`

export const Meta = styled(Grid)`
    grid-template-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    margin: -20px 0 8px;

    &:has(> :only-child) {
        justify-content: end;
    }

    ${EditorContentStyles}.compact + & {
        margin-top: 0;
    }
`

export const HelpButton = styled(Naked)`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 8px;
    align-items: center;
    ${captionSmallCss}

    &:where(:hover, :focus) {
        text-decoration: underline;
        color: var(--huma-color-foreground-default);
    }
`

export const Button = styled(Naked)`
    width: 32px;
    height: 32px;
    padding: 0;
    font-size: 16px;
    border-radius: 4px;

    &.active {
        background-color: var(--huma-color-surface-constructive-minimal);
        box-shadow: none;

        &:hover {
            background-color: var(--huma-color-surface-constructive-minimal-hover);
        }
    }

    &:not(.active):hover {
        box-shadow: inset 0 0 0 2px var(--huma-color-surface-constructive-minimal);
    }

    .box {
        display: block;
        width: 24px;
        height: 20px;
        box-shadow: inset 0 0 0 1px var(--huma-color-border-default);
        border-radius: 4px;
    }

    em {
        margin-inline-start: -2px;
    }

    small {
        font-size: 12px;
        vertical-align: text-bottom;
    }

    svg {
        ${svgStroke('small')}
    }
`

export const TextButton = styled(Plain)`
    height: 24px;
    padding: 0 8px;
    line-height: 24px;
`

export const Separator = styled.div`
    width: 16px;
    height: 32px;
`