import styled from 'styled-components'
import { Fieldset as FieldsetBase, Control } from 'components/form/field/s'
import { flexColumnsCss, FlexChildShrink, flexChildCss } from 'components/flex'
import { Plain } from 'components/button'
import { Grid } from 'components/grid'

export const Fieldset = FieldsetBase

export const OptionColumns = styled.label`
    ${flexColumnsCss}
    align-items: center;
    min-height: 40px;
    padding: 8px 0;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }

    > * {
        pointer-events: none;
    }

    ${Fieldset}.reversed & {
        flex-direction: row-reverse;
    }

    ${Control}.framed & {
        position: relative;
        align-items: center;

        padding: 16px;

        &:not(:last-of-type)::after {
            content: "";
            position: absolute;
            bottom: 0;

            width: calc(100% - 32px);
            height: 1px;
            background-color: var(--huma-color-border-default);
        }
    }

    &:is(:hover, :focus) input:not(:disabled, :checked, :indeterminate) + label:not(.locked) {
        border-color: var(--huma-color-border-subtle-hover);
    }

    &:is(:hover, :focus) input:not(:disabled):is(:checked, :indeterminate) + label:not(.locked) {
        border-color: var(--huma-color-surface-constructive-bold-hover);
        background: var(--huma-color-surface-constructive-bold-hover);

        &.destructive {
            border-color: var(var(--huma-color-surface-destructive-bold-hover));
            background-color: var(--huma-color-surface-destructive-bold-hover);
        }
    }
`

export const CheckboxColumn = styled(FlexChildShrink)`
    ${Fieldset}:not(.reversed) & {
        margin-inline-end: 16px;
    }

    ${Fieldset}.reversed & {
        margin-inline-start: 16px;
    }
`

export const TextColumn = styled.div`
    ${flexChildCss}
    line-height: 20px;

    ${OptionColumns}.disabled & {
        color: var(--huma-color-foreground-disabled);
    }
`

export const UncapButton = styled(Plain)`
    height: 32px;
    font-size: 12px;
    line-height: 32px;
`

export const ShortcutLayout = styled(Grid)`
    grid-auto-flow: row;
    grid-template-columns: 1fr min-content;
    justify-content: space-between;
    gap: 16px;
`