import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { getEntityIcon } from 'components/entity'
import { UnitDrawable, UnitDrawableHeader, Name, Summary, UnitDrawableContent, Label, Warning } from './s'
import Select from 'components/form/input/select'
import { Plain } from 'components/button'
import Message from 'components/message'
import {
    EyeOff as None,
    Eye as Share,
    Edit as Owner
} from 'styled-icons/feather'

const AccessUnitUser = ({ unit, access, locked, formatAdminTooltip, update, remove, drawable, salt }) => {
    const { formatMessage } = useIntl()
    const Icon = getEntityIcon(unit.type, { organization: 'company' })

    const [expanded, setExpanded] = useState(true)

    const name = `access[${unit.type}][${unit.id}]`
    const id = `${salt}:access:${unit.type}:${unit.id}`

    const getValueIcon = type => {
        const options = type === 'share' ? shareOptions : ownerOptions
        const option = options.find(({ value }) => value === (access[type] ? type : 'none'))
        const Icon = optionIcon[option.value]

        return <Icon size={12} />
    }

    const sharedWithEveryone = unit.type === 'organization' && !!access.share

    return (
        <UnitDrawable
            {...drawable}
            header={(
                <UnitDrawableHeader {...(expanded ? { className: 'expanded' } : null)}>
                    <Icon size={24} />
                    <Name>{unit.name}</Name>
                    <Summary>
                        <span>
                            <span className="label">
                            <FormattedMessage
                                id="noun_members"
                                defaultMessage="Members" />
                            </span>
                            {getValueIcon('share')}
                        </span>
                        <span>
                            <span className="label">
                                <FormattedMessage
                                    id="noun_admins"
                                    defaultMessage="Admins" />
                            </span>
                            {getValueIcon('owner')}
                        </span>
                    </Summary>
                </UnitDrawableHeader>
            )}
            callback={setExpanded}
            trigger="header">
            <UnitDrawableContent>
                <Label
                    htmlFor={`${id}:share`}
                    className="horizontal small compact">
                    <FormattedMessage
                        id="noun_members"
                        defaultMessage="Members" />
                    {sharedWithEveryone && <Warning size={12} />}
                </Label>
                <Select
                    id={`${id}:share`}
                    name={`${name}[share]`}
                    value={access.share ?
                        'share' :
                        'none'
                    }
                    onChange={({ target: { value } }) => update(value, 'share')}
                    disabled={locked}
                    className="small">
                    {shareOptions.map(option => (
                        <option
                            value={option.value}
                            key={`${salt}:share:${option.value}`}>
                            {formatMessage(option.label)}
                        </option>
                    ))}
                </Select>
                <Label
                    htmlFor={`${id}:owner`}
                    className="horizontal small compact"
                    {...(formatAdminTooltip ? { optional: formatAdminTooltip(unit) } : null)}>
                    <FormattedMessage
                        id="noun_admins"
                        defaultMessage="Admins" />
                </Label>
                <Select
                    id={`${id}:owner`}
                    name={`${name}[owner]`}
                    value={access.owner ?
                        'owner' :
                        'none'
                    }
                    onChange={({ target: { value } }) => update(value, 'owner')}
                    disabled={locked}
                    className="small">
                    {ownerOptions.map(option => (
                        <option
                            value={option.value}
                            key={`${salt}:owner:${option.value}`}>
                            {formatMessage(option.label)}
                        </option>
                    ))}
                </Select>
                <Plain
                    onClick={remove}
                    className="destructive small">
                    <FormattedMessage
                        id="action_remove"
                        defaultMessage="Remove" />
                </Plain>
            </UnitDrawableContent>
            {sharedWithEveryone && (
                <Message
                    type="warning"
                    message={formatMessage({
                        id: 'document_access_everyone_warning',
                        defaultMessage: 'This document will be accessible by all employees in {organization}.'
                    }, { organization: unit.name })}
                    className="compact" />
            )}
        </UnitDrawable>
    )
}

const optionIcon = {
    none: None,
    share: Share,
    owner: Owner
}

const shareOptions = [
    {
        value: 'none',
        label: {
            id: 'role_permission_none',
            defaultMessage: 'No access'
        }
    },
    {
        value: 'share',
        label: {
            id: 'role_permission_read',
            defaultMessage: 'Only see'
        }
    }
]

const ownerOptions = [
    {
        value: 'none',
        label: {
            id: 'role_permission_none',
            defaultMessage: 'No access'
        }
    },
    {
        value: 'owner',
        label: {
            id: 'role_permission_read_write',
            defaultMessage: 'See and edit'
        }
    }
]

export default AccessUnitUser