import React from 'react'
import { OrderedList } from 'components/list'
import Person from './person'

const People = ({ actions, people = [], lastRef, salt }) => (
    <OrderedList>
        {people.map((person, index) => (
            <Person
                person={person}
                actions={actions}
                {...((!!lastRef && people.length === index + 1) ? { ref: lastRef } : null)}
                salt={`${salt}:people`}
                key={`${salt}:people:${person?.id ?? `deleted:${index}`}`} />
        ))}
    </OrderedList>
)

export default People
