import React, { useState } from 'react'
import Hero from './hero'
import EmojiPicker from 'modals/emoji-picker/content/picker'
import ColorPicker from './color-picker'
import Actions from './actions'

const SymbolPickerContent = ({ symbol: initialSymbol, salt, ...props }) => {
    const [symbol, setSymbol] = useState({
        emoji: initialSymbol?.emoji ?? null,
        colorToken: initialSymbol?.colorToken ?? null
    })

    const [changed, setChanged] = useState(false)

    const onEmojiPicked = emoji => {
        setSymbol({
            ...symbol,
            emoji: emoji.id
        })

        setChanged(true)
    }

    const onColorPicked = colorToken => {
        setSymbol({ ...symbol, colorToken })
        setChanged(true)
    }

    const {
        label,
        promotedEmoji = [],
        dismiss,
        doneAction,
        cancelAction,
    } = props

    const cancel = cancelAction()
    const done = doneAction({ picked: symbol })

    return (
        <>
            <Hero
                label={label}
                {...symbol} />
            <EmojiPicker
                {...symbol}
                promotedEmoji={promotedEmoji}
                pickEmoji={onEmojiPicked} />
            <ColorPicker
                {...symbol}
                pickColor={onColorPicked}
                salt={salt} />
            <Actions
                cancel={cancel}
                done={done}
                dismiss={dismiss}
                {...symbol}
                changed={changed} />
        </>
    )
}

export default SymbolPickerContent