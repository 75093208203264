import styled from 'styled-components'
import { Columns, FlexChildShrink } from 'components/flex'
import { Ghost } from 'components/button'

export const Pills = styled(Columns)`
    --__huma-component-field-shortcuts-spacing: 4px;

    justify-content: flex-start;
    flex-wrap: wrap;
    width: calc(100% + var(--__huma-component-field-shortcuts-spacing));
`

export const PillCoating = styled(FlexChildShrink)`
    margin-block-end: var(--__huma-component-field-shortcuts-spacing);
    margin-inline-end: var(--__huma-component-field-shortcuts-spacing);
`

export const Pill = styled(Ghost)`
    height: 28px;
    padding: 2px 8px 0;
    font-size: 14px;
    color: var(--huma-color-foreground-subtle);
    background-color: rgb(from var(--huma-color-surface-neutral-bold) r g b / .02);
    border-color: transparent;
    border-radius: 4px;

    &:where(:hover, :focus) {
        color: var(--huma-color-foreground-default);
        background-color: rgb(from var(--huma-color-surface-neutral-bold) r g b / .01);
        border-color: var(--huma-color-border-subtle-hover);
        box-shadow: none;
    }

    &.active {
        color: var(--huma-color-foreground-default);
        border-color: var(--huma-color-border-default-focus);
    }
`