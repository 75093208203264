import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { FormContext } from 'components/form/controller'
import { map, omit, size } from 'utilities/object'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { Field, Label } from 'components/form/field/s'
import { Control } from './s'
import Toggler from 'components/form/input/toggler'
import { FormattedAddress } from 'components/address'
import AddressFiller from 'modals/address-filler'
import { Error } from 'components/form/field/s'

const empty = null

export default class EditAddress extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            field = {},
            forwardedRef
        } = props

        let { value } = field

        if(!value) {
            value = empty
        }

        this.state = {
            value,
            filling: false
        }

        this.register()

        this.field = createRef()
        this.control = createRef()

        this.combinedControlRef = mergeRefs([
            this.control,
            forwardedRef
        ])
    }

    componentDidUpdate = ({ forwardedRef, name, field }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(valueChanged) {
            this.context.triggerChange(this.props.name)
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    register = (update = false) => {
        const {
            name,
            field = {}
        } = this.props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value) {
            value = empty
        }

        this.context.registerField(name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                filling: false
            }),

            validator: value => required ?
                !!size(value ?? {}) :
                true
        }, update)
    }

    dismissAddressFiller = () => this.setState({ filling: false })
    toggleFilling = () => this.setState(({ filling }) => ({ filling: !filling }))
    unset = () => this.onFilled(empty)

    onFilled = value => {
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })

        this.context.triggerChange(name)
    }

    render() {
        const {
            value,
            filling
        } = this.state

        const {
            className,
            field = {},
            picker = {},
            controlProps = {},
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            error: outsideError,
            ...props
        } = this.props

        const {
            touched,
            error: internalError
        } = getFieldState(this.context, name)

        const error = internalError || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const {
            required,
            softRequired,
            optional,
            unsettable = true
        } = field

        const id = `${salt}:${name}`

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control {...(!!outsideError ? { className: 'error-within' } : null)}>
                    {!!size(value ?? {}) && map(value, (part, key) => (
                        <input
                            type="hidden"
                            name={`${name}[${key}]`}
                            value={part || ''}
                            key={`${salt}:${key}:hidden`} />
                    ))}
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'field', 'type')}
                        id={id}
                        onClick={this.toggleFilling}
                        active={filling}
                        unset={(!!size(value ?? {}) && unsettable) && this.unset}
                        unsettable={unsettable}
                        type="location"
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        <FormattedAddress
                            address={value}
                            salt={salt} />
                    </Toggler>
                    <AddressFiller
                        {...picker}
                        salt={id}
                        show={filling}
                        address={value}
                        dismiss={this.dismissAddressFiller}
                        onDone={this.onFilled} />
                    <Error animate={!!outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
            </Field>
        )
    }
}

const getFieldState = (context, name) => {
    const {
        touched,
        errors,
        submitted
    } = context

    const fieldTouched = touched.includes(name)

    return {
        touched: fieldTouched,
        error: (name in errors) && (fieldTouched || submitted)
    }
}
