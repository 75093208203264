import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEditorMeta } from '../extensions/utilities'
import { size, map } from 'utilities/object'
import { ArrowSortedDown as ToggleArrow } from 'styled-icons/typicons'
import ContextMenu from 'widgets/context-menu'
import { Button, Icon, Section, RichLabel, ContextMenuText } from './s'
import Shortcut from '../help/shortcut'

const ToolbarExtras = ({ editor, every, getTrigger, setMarking, setPicking, active, groupingOverrides, salt }) => {
    const meta = useEditorMeta()

    const extras = {}

    const showForeignBold = every('Bold') && (!!groupingOverrides?.Bold ?
        groupingOverrides.Bold === 'extras' :
        false
    )

    const showForeignItalic = every('Italic') && (!!groupingOverrides?.Italic ?
        groupingOverrides.Italic === 'extras' :
        false
    )

    const showForeignUnderline = every('Underline') && (!!groupingOverrides?.Underline ?
        groupingOverrides.Underline === 'extras' :
        false
    )

    const showForeignLink = every('Link') && (!!groupingOverrides?.Link ?
        groupingOverrides.Link === 'extras' :
        false
    )

    const showForeignEmoji = every('Emoji') && (!!groupingOverrides?.Emoji ?
        groupingOverrides.Emoji === 'extras' :
        false
    )

    if(showForeignBold) {
        extras.Bold = {
            onClick: getTrigger('toggleBold'),
            disabled: !(editor.can().toggleBold?.() ?? true),
            ...activate(active.bold)
        }
    }

    if(showForeignItalic) {
        extras.Italic = {
            onClick: getTrigger('toggleItalic'),
            disabled: !(editor.can().toggleItalic?.() ?? true),
            ...activate(active.italic)
        }
    }

    if(showForeignUnderline) {
        extras.Underline = {
            onClick: getTrigger('toggleUnderline'),
            disabled: !(editor.can().toggleUnderline?.() ?? true),
            ...activate(active.underline)
        }
    }

    if(showForeignLink) {
        extras.Link = {
            onClick: () => {
                const link = editor.getAttributes('link')

                setMarking({
                    type: 'link',
                    ...(size(link) ? { link } : null)
                })
            },
            disabled: !(editor.can().toggleLink?.() ?? true),
            ...activate(active.link)
        }
    }

    if(showForeignEmoji) {
        extras.Emoji = {
            onClick: () => setPicking('emoji'),
            // disabled: !(editor.can().setEmoji?.() ?? true)
        }
    }

    const showNativeCode = every('Code') && (!!groupingOverrides?.Code ?
        groupingOverrides.Code === 'extras' :
        true
    )

    const showNativeHighlight = every('Highlight') && (!!groupingOverrides?.Highlight ?
        groupingOverrides.Highlight === 'extras' :
        true
    )

    const showNativeStrike = every('Strike') && (!!groupingOverrides?.Strike ?
        groupingOverrides.Strike === 'extras' :
        true
    )

    const showNativeSubscript = every('Subscript') && (!!groupingOverrides?.Subscript ?
        groupingOverrides.Subscript === 'extras' :
        true
    )

    const showNativeSuperscript = every('Superscript') && (!!groupingOverrides?.Superscript ?
        groupingOverrides.Superscript === 'extras' :
        true
    )

    const showNativeCodeBlock = every('CodeBlock') && (!!groupingOverrides?.CodeBlock ?
        groupingOverrides.CodeBlock === 'extras' :
        true
    )

    const showNativeHorizontalRule = every('HorizontalRule') && (!!groupingOverrides?.HorizontalRule ?
        groupingOverrides.HorizontalRule === 'extras' :
        true
    )

    if(showNativeCode) {
        extras.Code = {
            onClick: getTrigger('toggleCode'),
            disabled: !(editor.can().toggleCode?.() ?? true),
            ...activate(active.code)
        }
    }

    if(showNativeHighlight) {
        extras.Highlight = {
            onClick: getTrigger('toggleHighlight'),
            disabled: !(editor.can().toggleHighlight?.() ?? true),
            ...activate(active.highlight)
        }
    }

    if(showNativeStrike) {
        extras.Strike = {
            onClick: getTrigger('toggleStrike'),
            disabled: !(editor.can().toggleStrike?.() ?? true),
            ...activate(active.strike)
        }
    }

    if(showNativeSubscript) {
        extras.Subscript = {
            onClick: getTrigger('toggleSubscript'),
            disabled: !(editor.can().toggleSubscript?.() ?? true),
            ...activate(active.subscript)
        }
    }

    if(showNativeSuperscript) {
        extras.Superscript = {
            onClick: getTrigger('toggleSuperscript'),
            disabled: !(editor.can().toggleSuperscript?.() ?? true),
            ...activate(active.superscript)
        }
    }

    if(showNativeCodeBlock) {
        extras.CodeBlock = {
            onClick: getTrigger('toggleCodeBlock'),
            disabled: !(editor.can().toggleCodeBlock?.() ?? true),
            ...activate(active.codeBlock)
        }
    }

    if(showNativeHorizontalRule) {
        extras.HorizontalRule = {
            onClick: getTrigger('setHorizontalRule'),
            disabled: !(editor.can().setHorizontalRule?.() ?? true),
            ...activate(active.horizontalRule)
        }
    }

    if(!size(extras)) {
        return null
    }

    const actions = map(extras, (attributes, name) => {
        let {
            label,
            shortcut,
            icon
        } = meta[name]()

        if(typeof icon?.type === 'string') {
            icon = <Icon>{icon}</Icon>
        }

        const key = `${salt}:extra:${name.toLowerCase()}`

        return () => ({
            salt: key,
            ...(shortcut ? {
                richLabel: (
                    <RichLabel>
                        <ContextMenuText className="compact">
                            {label}
                        </ContextMenuText>
                        <Shortcut
                            shortcut={shortcut}
                            className="smaller"
                            salt={key} />
                    </RichLabel>
                )
            } : { label }),
            icon,
            ...attributes
        })
    })

    return (
        <Section className="extras">
            <ContextMenu
                className="small"
                salt={`${salt}:extras`}
                actions={actions}
                trigger={(
                    <Button
                        className="dynamic"
                        tabIndex={-1}>
                        <FormattedMessage
                            id="noun_more"
                            defaultMessage="More" />
                        <ToggleArrow size={16} />
                    </Button>
                )} />
        </Section>
    )
}

const activate = active => ({ effect: active ? 'constructive' : 'neutral' })

export default ToolbarExtras