import styled from 'styled-components'
import { CaptionSmall } from 'components/typography/caption'
import { Field } from 'components/form/field/s'
import { Plain } from 'components/button'

export const Wrapper = styled(CaptionSmall)`
    margin-block: -16px 24px;

    .compact + &,
    &.compact {
        margin-block: 8px 0;
    }

    *:where(form.horizontal) &,
    ${Field}.horizontal &,
    ${Field}.horizontal:not(.compact) + & {
        margin-block: -8px 16px;

        &.compact {
            margin-block-end: 0;
        }
    }
`

export const SelectButton = styled(Plain)`
    display: inline-flex;
`