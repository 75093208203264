import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import ActionsBase from 'components/form/actions'
import { Ghost as GhostBase, Plain as PlainBase } from 'components/button'
import { ghostCss, buttonCss } from 'components/button/s'

export const Actions = styled(ActionsBase)`
    ${belowTablet`
        flex-direction: column;
        row-gap: 16px;
    `}
`

const bigCss = css`
    border-width: 2px;
    width: 160px;
    height: 56px;
    justify-content: center;

    font-size: 20px;
    font-weight: 500;
`

export const Button = styled(GhostBase)`
    ${bigCss}
    transition-timing-function: ease-in-out;
    transition-duration: .25s, .25s, .25s, .1s;
    transition-property: color, border-color, background-color, outline-offset;

    &:not(.selected) {
        --button-border-color: var(--huma-color-border-default) !important;

        ${ghostCss}
    }

    &.selected {
        ${buttonCss}
    }
`

export const Plain = styled(PlainBase)`
    ${bigCss}
    width: auto;
    padding-inline: 8px;
`