import styled from 'styled-components'
import {
    svgStroke, hideScrollbar,
    belowPhone
} from 'utilities/styled'
import { FlexChildShrink } from 'components/flex'
import { textCss } from 'components/form/input/text'

export const Wrap = styled(FlexChildShrink)`
    .emoji-mart,
    .emoji-mart * {
        line-height: 1.15;
    }

    .emoji-mart {
        display: inline-block;
        border: 1px solid var(--huma-color-border-default);
        border-radius: 5px;
        width: 100% !important;
    }

    .emoji-mart .emoji-mart-emoji {
        padding: 8px;
        cursor: pointer;
    }

    .emoji-mart-bar {
        overflow-x: auto;
        border: 0 solid var(--huma-color-border-default);

        ${hideScrollbar}

        &:first-child {
            border-bottom-width: 1px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-child {
            border-top-width: 1px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .emoji-mart-anchors {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(36px, auto);
        line-height: 0;

        ${belowPhone`
            grid-auto-columns: minmax(32px, auto);
        `}
    }

    .emoji-mart-anchor {
        position: relative;
        display: block;
        flex: 1 1 auto;
        color: rgb(from var(--huma-color-foreground-default) r g b / calc(2/3));
        text-align: center;
        padding: 12px 4px;
        overflow: hidden;
        transition: color .1s ease-out;
        margin: 0;
        box-shadow: none;
        background: none;
        border: none;
        cursor: pointer;

        &:focus { outline: 0 }

        &:hover,
        &:focus,
        &.emoji-mart-anchor-selected {
            color: var(--huma-color-foreground-default);
        }
    }

    .emoji-mart-anchor-selected .emoji-mart-anchor-bar {
        bottom: 0;
    }

    .emoji-mart-anchor-bar {
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -2px;
        left: 0;
        background-color: var(--huma-color-surface-default);
    }

    .emoji-mart-anchors i {
        display: inline-block;
        width: 100%;
        max-width: 22px;
    }

    .emoji-mart-anchors svg {
        ${svgStroke}
    }

    .emoji-mart-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 270px;
        padding: 0 8px 8px;
        margin-top: 8px;
        will-change: transform;
    }

    .emoji-mart-search {
        margin-top: 8px;
        padding: 0 8px;
        position: relative;
    }

    .emoji-mart-search input {
        ${textCss}
        padding-left: 40px;
        background-image: var(--huma-select-arrow-url);
        background-repeat: no-repeat;
        background-position: left 12px top 50%, 0 0;
        background-size: 16px auto, 100%;
    }

    .emoji-mart-search-icon {
        display: none;
    }

    .emoji-mart-category {
        &:not(:last-child) {
            padding-bottom: 16px;
        }

        .emoji-mart-emoji {
            &:hover::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                background-color: var(--huma-color-surface-minimal);
                border-radius: 100%;
            }

            span {
                position: relative;
                z-index: 1;
                text-align: center;
            }
        }
    }

    .emoji-mart-category-label {
        z-index: 2;
        position: relative;
        position: -webkit-sticky;
        position: sticky;
        top: 0;

        span {
            display: block;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            padding: 8px 0;
            background-color: rgb(from var(--huma-color-surface-default) r g b / .95);
        }
    }

    .emoji-mart-category-list {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline-block;
        }
    }

    .emoji-mart-emoji {
        position: relative;
        display: inline-block;
        font-size: 0;
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        box-shadow: none;
    }

    .emoji-mart-emoji-native {
        font-family: "Windows Chromium Flag Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
    }

    .emoji-mart-no-results {
        font-size: 14px;
        text-align: center;
        padding-top: 70px;
        color: var(--huma-color-foreground-minimal);

        .emoji-mart-emoji {
            cursor: default;
        }

        .emoji-mart-category-label {
            display: none;
        }

        .emoji-mart-no-results-label {
            margin-top: .2em;
        }

        .emoji-mart-emoji:hover:before {
            content: none;
        }
    }

    .emoji-mart-no-results-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    .emoji-mart-preview {
        position: relative;
        height: 70px;
    }

    .emoji-mart-preview-emoji,
    .emoji-mart-preview-data,
    .emoji-mart-preview-skins {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .emoji-mart-preview-emoji {
        left: 12px;
    }

    .emoji-mart-preview-data {
        left: 68px; right: 12px;
        word-break: break-all;
    }

    .emoji-mart-preview-skins {
        right: 30px;
        text-align: right;

        &.custom {
            right: 10px;
            text-align: right;
        }
    }

    .emoji-mart-preview-name {
        font-size: 14px;
    }

    .emoji-mart-preview-shortname {
        font-size: 12px;
        color: #888;
    }
    .emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
    .emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
    .emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
        margin-left: .5em;
    }

    .emoji-mart-preview-emoticon {
        font-size: 11px;
        color: #bbb;
    }

    .emoji-mart-title {
        span {
            display: inline-block;
            vertical-align: middle;
        }

        .emoji-mart-emoji {
            padding: 0;
        }
    }

    .emoji-mart-title-label {
        color: #999A9C;
        font-size: 26px;
        font-weight: 300;
    }

    /* For screenreaders only, via https://stackoverflow.com/a/19758620 */
    .emoji-mart-sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }
`