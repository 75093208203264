import React, { memo, forwardRef } from 'react'
import { getUserAgent } from 'utilities/user-agent'
import isEqual from 'react-fast-compare'
import { pick } from 'utilities/object'
import { Keys, Key } from './s'

const Shortcut = forwardRef(({ shortcut, className, salt }, ref) => {
    if(!shortcut?.length || isMobile) {
        return null
    }

    const reference = {
        ...common,
        ...(isMacOS ? macos : windows)
    }

    return (
        <Keys
            {...(className ? { className } : null)}
            ref={ref}>
            {shortcut.map(key => {
                const symbol = (reference[key] ?? key)?.toUpperCase?.()

                return (
                    <Key key={`${salt}:shortcut:${symbol}`}>
                        {symbol}
                    </Key>
                )
            })}
        </Keys>
    )
})

export default memo(Shortcut, (previous, next) => {
    const props = ['shortcut', 'className', 'salt']
    return isEqual(pick(previous, ...props), pick(next, ...props))
})

const {
    isMobile,
    os: { isMacOS }
} = getUserAgent()

const common = {
    capslock: '⇪',
    shift: '⇧',
    arrowup: '↑',
    arrowright: '→',
    arrowdown: '↓',
    arrowleft: '←',
    enter: '↩',
    backspace: '⌫',
    delete: '⌦',
    escape: '⎋',
    tab: '⇥',
    pageup: '⇞',
    pagedown: '⇟',
    space: '␣'
}

const macos = {
    mod: '⌘',
    cmd: '⌘',
    command: '⌘',
    meta: '⌘',
    super: '⌘',
    control: '⌃',
    ctrl: '⌃',
    option: '⌥',
    opt: '⌥',
    alt: '⌥'
}

const windows = {
    mod: 'ctrl',
    control: 'ctrl',
    ctrl: 'ctrl',
    option: 'alt',
    opt: 'alt',
    meta: '❖',
    super: '❖',
    cmd: '❖',
    command: '❖'
}